import React, { Component, useEffect, useRef, useState } from "react";
import { Badge, Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import routes from "operations/routing/routes";
import soundMP3 from "assets/sound/simple-ringtone-84595.mp3";

const SocketToastComponent = (props) => {
	const [soundAudio, setSoundAudio] = useState(true);
	const audioRef = useRef();
	const handleClick = () => {
		setSoundAudio((p) => !p);
	};

	useEffect(() => {
		try {
			if (soundAudio) audioRef.current?.play?.();
			else audioRef.current?.pause?.();
		} catch (error) {
			alert("Audio playback requires browser access");
		}
	}, [soundAudio]);

	return (
		<div>
			{/* opacity-0 */}
			<div className="opacity-0">
				<audio autoplay loop ref={audioRef}>
					<source src={soundMP3} type="audio/mp3" />
				</audio>
			</div>
			<div className="d-flex flex-column ml-2">
				<div className="d-flex">
					<span className="mr-2">New Order Alert</span>

					<div className="cursor-pointer">
						{soundAudio ? <i className="mdi mdi-bell-ring" onClick={handleClick} /> : <i className="mdi mdi-bell-off" onClick={handleClick} />}
					</div>
				</div>
				<div className="d-flex mt-1 align-items-center">
					<small className="d-flex mr-2">Check and process the order</small>
					<Link to={routes.order.base + routes.order.list}>
						<Badge className="rounded-pill" size="sm" color="success" onClick={props?.closeToast}>
							View
						</Badge>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SocketToastComponent;
