import { useEffect, useRef, useState } from "react";
import generateQrCode from "utils/generateQrCode";

const QrCodeComponent = ({ text, roomNumber, options }) => {
	const [current, setCurrent] = useState();

	const qrCodeRef = useRef();
	// const qrCodeUrl = `https://room-services.net/sayfa/urunler?ouid=${param?.qrCode?.uuid}`

	console.log("* * * QrCodeComponent", { text, roomNumber, options });

	useEffect(() => {
		let a = JSON.stringify({ text, roomNumber, options });
		if (current == a) return;
		setCurrent(a);
		qrCodeRef.current.innerHTML = "";
		generateQrCode(text, qrCodeRef.current, roomNumber, options);
		// return ()=> setCurrent()
	}, [text, options]);

	return <div ref={qrCodeRef}></div>;
};

export default QrCodeComponent;
