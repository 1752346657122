import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { STATUSES, USER_TYPES, USER_TYPES_OBJECT } from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import { useTranslation } from "react-i18next";
import RoleSelector, { convertRoleData } from "containers/Role/RoleSelector";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";

const Add = () => {
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	const { t } = useTranslation();
	const navigation = useNavigate();
	let { id, hotel } = useParams();
	const location = useLocation();
	const previousQuery = location?.state?.query;
	const previousQueryParams = new URLSearchParams(previousQuery || {});
	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const URL_ID = id ? id : "";

	const typeOptions = [
		{ label: "User", value: "USER" },
		{ label: "Admin", value: "ADMIN" },
		{ label: "Admin", value: "ADMIN" },
	];

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getPermissions = async () => await axiosClient().get("/v1/admin/users/permissions");
	// const getPermissions = async () => await axiosClient().get("/v1/admin/users/permissions");
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	// ------------------------------------------------------- query Get
	// const permissionQuery = useQueryCustom({
	//   name: `permission_get`,
	//   url: getPermissions,
	// });
	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});
	const watchUserType = watch(fieldNames.userType);
	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		const params = new URLSearchParams(previousQuery || {});
		navigation(newRoute.base + newRoute.list + `?${previousQueryParams.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});
	// mojtaba@gmail.com
	// Password1234!
	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		let selectedStatus = STATUSES.find((item) => item.value === params.isActive);
		let permissions = {};

		// params?.permissions?.map((x) => {
		//   permissions[x] = true
		// })
		console.log({ params }, convertRoleData(params?.role));
		handleSelectThumbnail({ _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title });
		reset({
			...params,
			role: convertRoleData(params?.role),
			userType: USER_TYPES_OBJECT[params?.userType],
			isActive: selectedStatus,
			// permissions
			// image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
		});
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const values = getValues();

		// const permissions = []

		// if (values?.role?.value == "admin") {
		//   for (const key in values?.permissions) {
		//     const curr = values?.permissions[key];
		//     if (curr) permissions.push(key)
		//   }
		// }
		const requestData = {
			...values,
			role: values.role?.value,
			userType: values.userType?.value,
			phoneNumber: values.phoneNumber && values.phoneNumber.toString(),
			isActive: values.isActive ? values.isActive.value : "1",
			isEmailNotificationActive: !!values.isEmailNotificationActive,
			isPhoneNotificationActive: !!values.isPhoneNotificationActive,
			password: values.password || undefined,
		};
		console.log({ requestData });
		mutate(requestData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: t(`backAuthor`, { author: t(COMPONENT_NAMES[0]) }),
		link: newRoute.base + newRoute.list + `?${previousQueryParams.toString()}`,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base.base },
		{ title: t(COMPONENT_NAMES[0]), link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.result?.name} ${dataById?.data?.data?.result?.name}` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.image, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	const valuesGet = getValues();
	// const permissions = []
	// const selectPermission = (valuesGet?.permission)s?.filter((x) => Object.values(x)[0])
	// for (const key in valuesGet?.permissions) {
	//   const curr = valuesGet?.permissions[key];
	//   if (curr) permissions.push(key)

	// }
	// const permissionWatcher = watch(fieldNames.permissions)
	// const watchRole = watch("role")

	// const permissionsArray = useMemo(() => {
	//   let data = permissionQuery?.data?.data
	//   if (!data) return [];

	//   // Convert the data object into the desired array format
	//   const result = Object.keys(data).map((key) => {
	//     const innerKeys = Object.keys(data[key]);
	//     const items = innerKeys.map((innerKey) => {
	//       const isObjectValue = Object.keys(data[key][innerKey])
	//       let itemsY = false;
	//       if (isObjectValue?.length) {
	//         let innerKeysY = Object.keys(data[key][innerKey]);
	//         itemsY = innerKeysY.map((innerKeyZ) => {
	//           return ({
	//             label: innerKeyZ,
	//             value: false,
	//           })
	//         })
	//         // console.log({ innerKeysY, itemsY });
	//       }

	//       return ({
	//         label: innerKey,
	//         value: itemsY,
	//       })
	//     })
	//     return {
	//       label: key,
	//       isDisabled: false, // Assuming isDisabled is constant for all items
	//       items,
	//     };
	//   });

	//   return result

	//   // let data=[]
	//   // for (const key in object) {
	//   //   if (Object.hasOwnProperty.call(object, key)) {
	//   //     const curr = object[key];
	//   //     data.push()
	//   //   }
	//   // }
	// }, [permissionQuery])

	// const selectPermissionFormat = selectPermission?.map((x) => Object.keys(x)[0])
	console.log({
		valuesGet,
		// permissions
		// selectPermission, selectPermissionFormat
	});

	// useEffect(() => {
	//   setValue(fieldNames.role, null)
	// }, [watchUserType])

	// const permisssionsData= permissionQuery?.data?.data?.map((x)=>({label:x,value:x}))
	return (
		<div>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle
				title={URL_ID ? t(`editAuthor`, { author: t(COMPONENT_NAMES[0]) }) : t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) })}
				button={button}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldText
												require
												{...{
													name: fieldNames.name,
													register,
													placeholder: "name",
													label: "name",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.lastName,
													register,
													placeholder: "lastName",
													label: "lastName",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.email,
													register,
													placeholder: "email",
													label: "email",
													errors,
												}}
											/>
											<FieldText
												{...{
													name: fieldNames.phoneNumber,
													register,
													placeholder: "mobileNumber",
													label: "mobileNumber ",
													errors,
												}}
											/>
											<FieldText
												require
												type={showPass ? "text" : "password"}
												{...{
													// disabled: URL_ID,
													name: fieldNames.password,
													register,
													placeholder: "password",
													label: "password",
													errors,
													show: showPass,
													setShow: setShowPass,
												}}
											/>
											<FieldText
												require
												type={showConfirmPass ? "text" : "password"}
												{...{
													// disabled: URL_ID,
													name: fieldNames.confirm_password,
													register,
													placeholder: "confirmPassword",
													label: "confirmPassword",
													errors,
													show: showConfirmPass,
													setShow: setShowConfirmPass,
												}}
											/>
											<FieldSelect
												{...{
													Controller,
													name: fieldNames.userType,
													register,
													label: "userType",
													errors,
													control,
													options: USER_TYPES,
												}}
											/>

											<RoleSelector
												require
												isDisabled={!watchUserType?.value}
												{...{
													Controller,
													name: fieldNames.role,
													register,
													label: "role",
													errors,
													control,
													setParams: {
														...(watchUserType?.value && { userType: watchUserType?.value }),
													},
												}}
											/>
											{/* <FieldSelect
                        require
                        {...{
                          Controller,
                          name: fieldNames.role,
                          register,
                          label: "role",
                          errors,
                          control,
                          options: ROLES,
                          disabled: hotel
                        }}
                      /> */}

											<FieldSelect
												{...{
													Controller,
													name: fieldNames.isActive,
													register,
													label: "status",
													errors,
													control,
													options: STATUSES,
												}}
											/>
											<CheckBox
												{...{
													Controller,
													name: fieldNames.isEmailNotificationActive,
													register,
													label: "isEmailNotificationActive",
													errors,
													control,
												}}
											/>
											<CheckBox
												{...{
													Controller,
													name: fieldNames.isPhoneNotificationActive,
													register,
													label: "isPhoneNotificationActive",
													errors,
													control,
												}}
											/>
											{/* <GalleryComponent
                        isRequired
                        {...{
                          handleModal: handleGalleryModal,
                          selectedPhoto: thumbnail,
                          labe: "image",
                          name: fieldNames.image,
                          Controller,
                          control,
                          errors,
                          register,
                        }}
                      /> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* {["admin", "super_admin"].includes(watchRole?.value) ?
              <div className="col-12 border p-4 mt-4">
                <div className="">

                  <Row className="b-1">
                    <Col lg={6}>
                      <h2 className="">Permissions    </h2>
                    </Col>

                  </Row>
                  <div className="mt-5">
                    <div className="col-12">
                      <div className="basic-form"></div>

                      <Accordion defaultActiveKey="0">
                        {
                          permissionsArray?.map((x, i) => {
                            if (watchRole?.value != "super_admin" && x.label == "users") return;
                            const values = getValues()

                            let selectAll = true;

                            // values?.[fieldNames.permissions]?.[a.label]?.value
                            x?.items?.forEach((a) => selectAll = selectAll && values?.[fieldNames.permissions]?.[x.label]?.[a.label])

                            const handleSelectAll = () => {
                              x?.items?.forEach((a) =>
                                setValue(`${[fieldNames.permissions]}.${x.label}.${a.label}`, selectAll ? false : true)
                              )
                            }
                            console.log({ values, x, selectAll }, { permission: values?.[fieldNames.permissions]?.[x.label] });


                            // const isArray = x?.items?.filter((y) => y?.value?.length)
                            // const items = x?.items?.filter((y) => !y?.value?.length)
                            // console.log({ isArray, items });

                            return (
                              <Accordion.Item eventKey={i} key={x.label}>
                                <Accordion.Header>
                                  {x.label}
                                  {selectAll ? "(Full)" : ""}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Row>
                                    <Col>
                                      <Button onClick={handleSelectAll} className={"d-flex align-items-center"} size="sm">
                                        <div className={"gallery-add-title"}>{selectAll ? "Remove all" : "Select all"}</div>
                                      </Button>
                                    </Col>
                                    {x?.items?.map((y) => {
                                      return (
                                        <Col key={y.label}>
                                          <CheckBox
                                            {...{
                                              Controller,
                                              name: `${[fieldNames.permissions]}.${x.label}.${y.label}`,
                                              register,
                                              label: y.label,
                                              control,
                                            }}
                                          />
                                        </Col>
                                      )
                                    })}

                                  </Row>
                                
                                </Accordion.Body>
                              </Accordion.Item>
                            )
                          })
                        }
                      </Accordion>

                    </div>
                  </div>
                </div>
              </div> : ""} */}
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{t("actions")}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} disabled={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>

						{/* {watchRole?.label == "admin" ? <div className="row mt-4">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{t("permissions")}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      {
                        Object.values(permissionQuery?.data?.data || {})?.map((x, i) => {
                          return (
                            <CheckBox
                              {...{
                                Controller,
                                name: `permissions.${x}`,
                                register,
                                label: t(x),
                                errors,
                                control,
                              }}
                            />
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
              </Col>
            </div> : ""} */}
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
