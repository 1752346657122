import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { INVOICE_DATA_OBJECT, PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import {
  changePriceFormat,
  numberWithCommas,
} from "./../../../utils/calculators";
import isoToDate from "utils/isoToDate";
import { Button } from "react-bootstrap";

export const tableData = ({ onClick, newRoute, active, ...props }) => {
  let data = [
    {
      header: "hotel",
      Component: ({ param }) => {
        const { base, edit } = routes.hotel;
        return param.hotel ? (
          <Link to={`${base + edit}/${param.hotel.id}`} target="_blank">
            {" "}
            {param.hotel?.name}{" "}
          </Link>
        ) : (
          "Unknown"
        );
      },
    },
    {
      header: "region",
      Component: ({ param }) => {
        const { base, edit } = routes.region;
        return param.region ? (
          <Link to={`${base + edit}/${param?.region?.id}`} target="_blank">
            {" "}
            {param?.region?.name}{" "}
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      header: "orderCount",
      Component: ({ param }) => numberWithCommas(param.orderCount),
    },
    {
      header: "commission",
      Component: ({ param }) =>
        "£ " + changePriceFormat(param?.totalCommission, 2),
    },
    {
      header: "totalAmount",
      Component: ({ param }) => "£ " + changePriceFormat(param.totalAmount, 2),
    },
    {
      header: "roomCount",
      Component: ({ param }) =>
        param?.hotel?.roomCount >= 0
          ? numberWithCommas(param?.hotel?.roomCount)
          : "-",
    },
  ];

  if (props.isHotel)
    data = [
      // {
      //   header: "product",
      //   Component: ({ param }) => {
      //     // const { base, edit } = routes.product;

      //     return (
      //       <div className="">
      //         {param.products?.map((x) => {

      //           return (
      //             <ul className="" key={x.name}>
      //               <li className="d-flex text-nowrap justify-content-start align-items-center ">
      //                 <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
      //                   {"name"}
      //                 </strong>

      //                 {" "}
      //                 <div className="mx-2">{":"}</div>
      //                 <div className="text-start">
      //                   {/* <Link to={`${base + edit}/${x?.id}`} target="_blank"> */}
      //                   {x.name}
      //                   {/* </Link> */}
      //                 </div>{" "}
      //               </li>
      //               <li className="d-flex text-nowrap justify-content-start align-items-center ">
      //                 <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
      //                   {"commission"}
      //                 </strong>{" "}
      //                 <div className="mx-2">{":"}</div>
      //                 <div className="text-start">{x.commission}</div>{" "}
      //               </li>
      //               <li className="d-flex text-nowrap justify-content-start align-items-center ">
      //                 <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
      //                   {"price"}
      //                 </strong>{" "}
      //                 <div className="mx-2">{":"}</div>
      //                 <div className="text-start">{x.price}</div>{" "}
      //               </li>
      //               <li className="d-flex text-nowrap justify-content-start align-items-center ">
      //                 <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
      //                   {"createdAt"}
      //                 </strong>{" "}
      //                 <div className="mx-2">{":"}</div>
      //                 <div className="text-start">{isoToDate(x.createdAt)}</div>{" "}
      //               </li>
      //               <hr />
      //             </ul>
      //           );
      //         })}
      //       </div>
      //     )

      //   },
      // },
      {
        header: "orderNumber",
        Component: ({ param }) => param.orderNumber,
      },
      {
        header: "products",
        Component: ({ param }) => {
          return (
            <Button variant="info" size="sm">
              view
            </Button>
          );
        },
        subTable: {
          getData: (param) => param?.products,
          options: [
            {
              header: "productName",
              Component: ({ param }) => {
                // const { base, edit } = routes.product;
                // const currRegion = props?.isAdmin ? param?.product : param?.hotel?.product

                return (
                  // props?.isAdmin ?
                  //   <Link to={`${base + edit}/${curr?.id}`} target="_blank">
                  //     {" "}
                  //     {param?.name}{" "}
                  //   </Link>
                  //   :
                  param?.name
                );
              },
            },
            {
              header: "quantity",
              Component: ({ param }) => param?.quantity || "-",
            },
            {
              header: "commissionRate",
              Component: ({ param }) =>
                changePriceFormat(param?.commission) || "-",
            },

            {
              header: "price",
              Component: ({ param }) => changePriceFormat(param?.price) || "-",
            },
            {
              header: "createdAt",
              Component: ({ param }) => isoToDate(param?.createdAt) || "-",
            },
          ],
        },
      },
      {
        header: "price",
        Component: ({ param }) => changePriceFormat(param?.totalPrice) || "-",
      },
      {
        header: "commission",
        Component: ({ param }) =>
          changePriceFormat(param?.totalCommission) || "-",
      },

      // {
      // 	header: "totalCommission",
      // 	Component: ({ param }) => "£ " + changePriceFormat(param.totalCommission, 2),
      // },

      // // {
      // //   header: "kdv",
      // //   Component: ({ param }) => numberWithCommas(param.kdv),
      // // },
      // {
      // 	header: "totalPrice",
      // 	Component: ({ param }) => "£ " + changePriceFormat(param.totalPrice, 2),
      // },
    ];

  return data;
};
