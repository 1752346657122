import React, { Component, useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { AccordionContext, Dropdown } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import routes from "operations/routing/routes";
import { PERMISSIONS } from "enumerations";
import { useSelector } from "react-redux";
import { findElementsInTwoArrays } from "utils/findElementsInTwoArrays";
import { useTranslation } from "react-i18next";
import getDataInObjectDepth from "utils/getDataInObject";

const Sidebar = () => {
  const [state, setState] = useState({});

  let location = useLocation();

  function toggleMenuState(menuState) {
    if (state[menuState]) {
      setState((p) => ({ ...p, [menuState]: false }));
    } else if (Object.keys(state).length === 0) {
      setState((p) => ({ ...p, [menuState]: true }));
    } else {
      Object.keys(state).forEach((i) => {
        setState((p) => ({ ...p, [i]: false }));
      });
      setState((p) => ({ ...p, [menuState]: true }));
    }
  }

  function onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(state).forEach((i) => {
      setState((p) => ({ ...p, [i]: false }));
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState((p) => ({ ...p, [obj.state]: true }));
      }
    });
  }

  function isPathActive(path) {
    return location.pathname.startsWith(path);
  }

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  useEffect(() => {
    onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [location]);

  function ContextAwareToggle({
    children,
    eventKey,
    callback,
    icon,
    dropdown,
  }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <div
        className={isCurrentEventKey ? "nav-link menu-expanded" : "nav-link"}
        onClick={decoratedOnClick}
        data-toggle="Accordion.collapse"
      >
        {icon}
        <span className="menu-title">{children}</span>
        {dropdown ? <i className="menu-arrow"></i> : ""}
      </div>
    );
  }
  const [t] = useTranslation();

  const adminState = useSelector((state) => state.admin);
  const isBranch = adminState.data.userType === "branch";
  const isHotel = adminState.data.userType === "hotel";
  const isAdmin = adminState.data.userType?.includes("admin");

  const sidebar = [
    {
      icon: <i className="mdi mdi-home menu-icon"></i>,
      title: "dashboard",
      link: routes.dashboard.base,
      permission: true,
      name: "dashboard",
      // permissions: [PERMISSIONS.MEDIA, PERMISSIONS.BLOG, PERMISSIONS.NEWSLETTER, PERMISSIONS.PERMISSION, PERMISSIONS.ROLE, PERMISSIONS.SETTING, PERMISSIONS.USER],
    },
    {
      icon: <i className="mdi mdi-archive menu-icon"></i>,
      title: isAdmin ? "order" : t("authorDetails", { author: t("order") }), //isAdmin ? "order" : "orderDetails",
      link: routes.order.base,
      permission: true,
      name: "orders",
    },
    {
      icon: <i className="mdi mdi-image-filter-frames menu-icon"></i>,
      title: "reports",
      // link: routes.report.base,
      permission: true,
      name: routes.costReport.name,
      supports: [routes.hourlyReport.name, routes.languageReport.name],
      data: [
        {
          title: "hourly",
          link: routes.hourlyReport.base,
          permission: true,
          name: "hourlyReports",
        },
        {
          title: "cost",
          link: routes.costReport.base,
          permission: true,
          name: "costReports",
        },
        {
          title: "language",
          link: routes.languageReport.base,
          permission: true,
          name: "languageReports",
        },
      ],
    },

    {
      icon: <i className="mdi mdi-cash-multiple   menu-icon"></i>,
      title: "multiplePrice",
      link: routes.multiplePrice.base,
      permission: true,
      name: "mpu",
    },
    {
      icon: <i className="mdi mdi-file-document menu-icon"></i>,
      title: isAdmin
        ? "finance"
        : t("authorDetails", { author: t("financial") }),
      ...(isBranch
        ? { link: routes.financeBranches.base, name: "financeBranches" }
        : isHotel
        ? { link: routes.financeHotels.base, name: "financeHotels" }
        : { name: "finance", supports: ["financeHotels", "financeBranches"] }),
      // ...(isHotel && { link: routes.financeHotels.base }),
      permission: true,
      // name: "financeInvoice",
      ...(isAdmin && {
        data: [
          // {
          //   title: "info",
          //   name: "finance",
          //   permission: true,
          //   link: routes.finance.base,
          // },
          // {
          // 	title: "invoice",
          // 	name: "invoice",
          // 	permission: true,
          // 	link: routes.invoice.base,
          // },
          // {
          // 	title: "Hotel invoice",
          // 	name: "invoice",
          // 	permission: true,
          // 	link: routes.invoice.base,
          // },

          {
            title: "hotels",
            name: "financeHotels",
            permission: true,
            link: routes.financeHotels.base,
          },
          {
            title: "Branches",
            name: "financeBranches",
            permission: true,
            link: routes.financeBranches.base,
          },
        ],
      }),
    },
    // {
    //   icon: <i className="mdi mdi-clipboard menu-icon"></i>,
    //   title: isAdmin ? "invoice" : t("authorDetails", { author: t("invoice") }),
    //   ...(isBranch
    //     ? { link: routes.invoiceBranch.base, name: "invoiceBranches" }
    //     : isHotel
    //     ? { link: routes.invoiceHotel.base, name: "invoiceHotels" }
    //     : { name: "invoice", supports: ["invoiceHotels", "invoiceBranches"] }),
    //   // ...(isHotel && { link: routes.financeHotels.base }),
    //   // permission: true,
    //   // name: "financeInvoice",
    //   ...(isAdmin && {
    //     data: [
    //       {
    //         title: "hotels",
    //         name: "invoiceHotels",
    //         permission: true,
    //         link: routes.invoiceHotel.base,
    //       },
    //       {
    //         title: "Branches",
    //         name: "invoiceBranches",
    //         permission: true,
    //         link: routes.invoiceBranch.base,
    //       },
    //     ],
    //   }),
    // },
    // ...(isHotel
    // 	? [
    // 			{
    // 				icon: <i className="mdi mdi-file-document menu-icon"></i>,
    // 				title: "invoice",
    // 				name: "invoice",
    // 				permission: true,
    // 				link: routes.invoiceHotel.base,
    // 			},
    // 	  ]
    // 	: []),
    {
      icon: <i className="mdi mdi-basket menu-icon"></i>,
      title: isAdmin ? "product" : t("authorDetails", { author: t("product") }),
      // link: routes.product.base,
      permission: true,
      name: "products",
      supports: ["pendingProduct"],
      data: [
        {
          title: "Product List",
          name: "products",
          permission: true,
          link: routes.product.base,
        },
        {
          title: "productPriceUpdateList",
          name: "pendingProduct",
          permission: true,
          link: routes.PendingPriceUpdate.base,
        },
      ],
    },
    // {
    //   icon: <i className="mdi mdi-clock menu-icon"></i>,
    //   title: "productHour",
    //   link: routes.hour.base,
    //   permission: true,
    //   name: "produtHour",
    // },
    {
      icon: <i className="mdi mdi-chart-pie menu-icon"></i>,
      title: "category",
      link: routes.category.base,
      permission: true,
      name: "productCategories",
    },
    // {
    //   icon: <i className="mdi mdi-account menu-icon"></i>,
    //   title: "Members",
    //   permissions: [PERMISSIONS.USER, PERMISSIONS.ADMIN],
    //   data: [
    //     {
    //       icon: <i className="mdi mdi-format-list-bulleted menu-icon"></i>,
    //       title: "Admins",
    //       link: routes.member.admin.base,
    //       permissions: [PERMISSIONS.ADMIN],
    //     },
    //     {
    //       icon: <i className="mdi mdi-format-list-bulleted menu-icon"></i>,
    //       title: "Users",
    //       link: routes.member.user.base,
    //       permissions: [PERMISSIONS.USER],
    //     },
    //   ],
    // },

    {
      icon: <i className="mdi mdi-account menu-icon"></i>,
      title: "user",
      link: routes.user.base,
      permission: true,
      name: "users",
    },
    {
      icon: <i className="mdi mdi-login menu-icon"></i>,
      title: "activities",
      link: routes.activities.base,
      permission: true,
      name: "activities",
    },
    {
      icon: <i className="mdi mdi-source-branch menu-icon"></i>,
      title: "region",
      link: routes.region.base,
      permission: true,
      name: "regions",
    },
    ...(isBranch
      ? [
          {
            icon: <i className="mdi mdi-file-check menu-icon"></i>,
            title: isAdmin
              ? "contract"
              : t("authorDetails", { author: t("contract") }),
            link: routes.branch.base + routes.branch.contract,
            permission: true,
            name: "branches.viewContract",
          },
        ]
      : [
          {
            icon: <i className="mdi mdi-source-branch menu-icon"></i>,
            title: "branch",
            link: routes.branch.base,
            permission: true,
            name: "branches",
            supports: ["branches", "viewContract"],
            // data: [
            // 	{
            // 		title: "list",
            // 		name: "branches",
            // 		permission: true,
            // 		link: routes.branch.base,
            // 	},
            // 	{
            // 		title: "contracts",
            // 		name: "viewContract",
            // 		permission: true,
            // 		link: routes.branch.base + routes.branch.contract,
            // 	},
            // ],
          },
        ]),
    ...(isHotel
      ? [
          {
            icon: <i className="mdi mdi-file-check menu-icon"></i>,
            title: isAdmin
              ? "contract"
              : t("authorDetails", { author: t("contract") }),
            link: routes.hotel.base + routes.hotel.contract,
            permission: true,
            name: "hotels.viewContract",
          },
        ]
      : [
          {
            icon: <i className="mdi mdi-hotel menu-icon"></i>,
            title: "hotel",
            link: routes.hotel.base,
            permission: true,
            name: "hotels",
            supports: ["hotels", "viewContract"],
            // data: [
            // 	{
            // 		title: "list",
            // 		name: "hotels",
            // 		permission: true,
            // 		link: routes.hotel.base,
            // 	},
            // 	{
            // 		title: "contracts",
            // 		name: "viewContract",
            // 		permission: true,
            // 		link: routes.hotel.base + routes.hotel.contract,
            // 	},
            // ],
          },
        ]),
    {
      icon: <i className="mdi mdi-food menu-icon"></i>,
      title: "menus",
      link: routes.menus.base,
      permission: true,
      name: "menus",
    },
    {
      icon: <i className="mdi mdi-cards menu-icon"></i>,
      title: "discount",
      link: routes.discount.base,
      permission: true,
      name: "discounts",
    },
    // {
    //   icon: <i className="mdi mdi-shopping menu-icon"></i>,
    //   title: "places",
    //   link: routes.places.base,
    //   permission: true,
    //   name: "places",
    // },
    // {
    //   icon: <i className="mdi mdi-motorbike menu-icon"></i>,
    //   title: "deliveries",
    //   link: routes.deliveries.base,
    //   permission: true,
    //   name: "deliveries",
    // },
    {
      icon: <i className="mdi mdi-image-multiple menu-icon"></i>,
      title: "gallery",
      link: routes.gallery.base,
      permission: true,
      name: "files",
    },
    {
      icon: <i className="mdi mdi-google-translate menu-icon"></i>,
      title: "language",
      link: routes.language.base,
      permission: true,
      name: "languages",
    },
    {
      icon: <i className="mdi mdi-settings menu-icon"></i>,
      title: "setting",
      link: routes.setting.base,
      permission: true,
      name: "settings",
    },

    {
      icon: <i className="mdi mdi-account-convert menu-icon"></i>,
      title: "role",
      link: routes.role.base,
      permission: true,
      name: "roles",
    },
    {
      icon: <i className="mdi mdi-account-convert menu-icon"></i>,
      title: "permission",
      link: routes.permission.base,
      permission: true,
      name: "permissions",
    },
    // {
    //   icon: <i className="mdi mdi-account-convert menu-icon"></i>,
    //   title: "Roles",
    //   link: routes.role.base,
    //   permissions: [PERMISSIONS.ROLE],
    // },
    // {
    //   icon: <i className="mdi mdi-email-open menu-icon"></i>,
    //   title: "Newsletters",
    //   link: routes.newsletter.base,
    //   permissions: [PERMISSIONS.NEWSLETTER],
    // },
    // {
    //   icon: <i className="mdi mdi-book-open menu-icon"></i>,
    //   title: "Blogs",
    //   permissions: [PERMISSIONS.BLOG],

    //   data: [
    //     {
    //       icon: <i className="mdi mdi-format-list-bulleted menu-icon"></i>,
    //       title: "Categories",
    //       link: routes.blog.category.base,
    //     },
    //     {
    //       icon: <i className="mdi mdi-format-list-bulleted menu-icon"></i>,
    //       title: "Tags",
    //       link: routes.blog.tag.base,
    //     },
    //     {
    //       icon: <i className="mdi mdi-book-open-page-variant menu-icon"></i>,
    //       title: "Posts",
    //       link: routes.blog.post.base,
    //     },
    //   ],
    // },
    // {
    //   icon: <i className="mdi mdi-folder-multiple-image menu-icon"></i>,
    //   title: "Media",
    //   permissions: [PERMISSIONS.MEDIA, PERMISSIONS.BLOG, PERMISSIONS.USER],
    //   data: [
    //     {
    //       icon: <i className="mdi mdi-format-list-bulleted menu-icon"></i>,
    //       title: "Folders",
    //       link: routes.media.folder.base,
    //     },
    //     {
    //       icon: <i className="mdi mdi-format-list-bulleted menu-icon"></i>,
    //       title: "Galleries",
    //       link: routes.media.gallery.base,
    //     },
    //   ],
    // },
  ];
  return (
    <nav className="sidebar sidebar-offcanvas sidebar-fixed" id="sidebar">
      <Accordion defaultActiveKey="0">
        <ul className="nav">
          {sidebar.map((x, i) => {
            const permission = adminState?.data?.permissions;
            const [_, depth1] = x?.name?.split?.(".");
            // console.log({ depth1 });

            const isValid =
              x?.supports?.find((x) => permission?.[x]) || !depth1
                ? permission?.[x?.name]
                : getDataInObjectDepth(x?.name, permission);
            // console.log({ isValid, permission, x }, x.title, x.permission);
            const parentPermission = permission === true ? true : isValid;

            return (x.permission ? parentPermission : true) ? (
              x.link ? (
                <li key={x.title}>
                  <NavLink
                    className={"nav-item nav-link"}
                    activeClassName={"active"}
                    to={x.link}
                  >
                    <ContextAwareToggle
                      eventKey={i}
                      icon={x.icon}
                      dropdown={x?.data}
                    >
                      {t(x.title)}
                    </ContextAwareToggle>
                    {x?.data ? (
                      <Accordion.Collapse eventKey={i}>
                        <ul className="nav flex-column sub-menu">
                          {x.data.map((y) => {
                            // const childPermission = parentPermission === true ? true : parentPermission[y.name];
                            const childPermission =
                              permission === true
                                ? true
                                : permission?.[y?.name];
                            // console.log({ permission, x, childPermission }, x.title);

                            return !y.permission || childPermission ? (
                              <li key={y.title} className="nav-item">
                                <NavLink
                                  className={"nav-link"}
                                  activeClassName={"nav-link active"}
                                  to={y.link}
                                >
                                  {t(y.title)}
                                </NavLink>
                              </li>
                            ) : (
                              ""
                            );
                          })}
                        </ul>
                      </Accordion.Collapse>
                    ) : (
                      ""
                    )}
                  </NavLink>
                </li>
              ) : (
                <li key={x.title} className="nav-item nav-link">
                  <ContextAwareToggle
                    eventKey={i}
                    icon={x.icon}
                    dropdown={x?.data}
                  >
                    {t(x.title)}
                  </ContextAwareToggle>
                  {x?.data ? (
                    <Accordion.Collapse eventKey={i}>
                      <ul className="nav flex-column sub-menu">
                        {x.data.map((y) => {
                          const childPermission =
                            permission === true ? true : permission?.[y?.name];
                          // console.log({ permission, x, childPermission }, x.title);
                          return !y.permission || childPermission ? (
                            <li key={y.title} className="nav-item">
                              <NavLink
                                className={"nav-link"}
                                activeClassName={"nav-link active"}
                                to={y.link}
                              >
                                {t(y.title)}
                              </NavLink>
                            </li>
                          ) : (
                            ""
                          );
                        })}
                      </ul>
                    </Accordion.Collapse>
                  ) : (
                    ""
                  )}
                </li>
              )
            ) : (
              ""
            );
          })}

          {/* {sidebar.map((x, i) => {
            const permission = adminState?.data?.operation?.permissions;
            const disabled = adminState.data.operation.disabled[x?.name]
            const parentPermission = permission === true ? true : permission?.[x?.name];
            // console.log({ parentPermission });
            return (x.permission ? parentPermission : true) && !disabled ? (
              x.link ? (
                <li key={x.title}>
                  <NavLink className={"nav-item nav-link"} activeClassName={"active"} to={x.link}>
                    <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                      {t(x.title)}
                    </ContextAwareToggle>
                    {x?.data ? (
                      <Accordion.Collapse eventKey={i}>
                        <ul className="nav flex-column sub-menu">
                          {x.data.map((y) => {
                            const childPermission = permission === true ? true : permission?.[x?.name];
                            console.log({ childPermission });
                            return !y.permission || childPermission ? (
                              <li key={y.title} className="nav-item">
                                <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                  {y.title}
                                </NavLink>
                              </li>
                            ) : (
                              ""
                            );
                          })}
                        </ul>
                      </Accordion.Collapse>
                    ) : (
                      ""
                    )}
                  </NavLink>
                </li>
              ) : (
                <li key={x.title} className="nav-item nav-link">
                
                  <ContextAwareToggle eventKey={i} icon={x.icon} dropdown={x?.data}>
                    {t(x.title)}
                  </ContextAwareToggle>
                  {x?.data ? (
                    <Accordion.Collapse eventKey={i}>
                      <ul className="nav flex-column sub-menu">
                        {x.data.map((y) => {
                          const childPermission = permission === true ? true : permission?.[y?.name];
                          // console.log({ childPermission, permission }, y?.name);
                          // !y.permissions ||
                          //   findElementsInTwoArrays(
                          //     adminState.data.role.permissions,
                          //     y.permissions ? y.permissions : []
                          //   ) 
                          return !y.permission || childPermission
                            ? (
                              <li key={y.title} className="nav-item">
                                <NavLink className={"nav-link"} activeClassName={"nav-link active"} to={y.link}>
                                  {y.title}
                                </NavLink>
                              </li>
                            ) : (
                              ""
                            );
                        })}
                      </ul>
                    </Accordion.Collapse>
                  ) : (
                    ""
                  )}
                 
                </li>
              )
            ) : (
              ""
            );
          })} */}
          {/* <li className={isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
            <div className={state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('basicUiMenuOpen')} data-toggle="Accordion.collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>Basic UI Elements</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <ContextAwareToggle eventKey={0}>Basic UI Elements</ContextAwareToggle>
            <Accordion.Collapse eventKey={0}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link'} to="/basic-ui/buttons"><Trans>Buttons</Trans></Link></li>
                <li className="nav-item"> <Link className={isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link'} to="/basic-ui/dropdowns"><Trans>Dropdowns</Trans></Link></li>
                <li className="nav-item"> <Link className={isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link'} to="/basic-ui/typography"><Trans>Typography</Trans></Link></li>
              </ul>
            </Accordion.Collapse>
          </li> */}
          {/* <li className={isPathActive('/form-elements') ? 'nav-item active' : 'nav-item'}>
            <div className={state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('formElementsMenuOpen')} data-toggle="Accordion.collapse">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title"><Trans>Form Elements</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Accordion.Collapse eventKey={state.formElementsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link'} to="/form-elements/basic-elements"><Trans>Basic Elements</Trans></Link></li>
              </ul>
            </Accordion.Collapse>
          </li>
          <li className={isPathActive('/tables') ? 'nav-item active' : 'nav-item'}>
            <div className={state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('tablesMenuOpen')} data-toggle="Accordion.collapse">
              <i className="mdi mdi-table-large menu-icon"></i>
              <span className="menu-title"><Trans>Tables</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Accordion.Collapse eventKey={state.tablesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link'} to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
              </ul>
            </Accordion.Collapse>
          </li>
          <li className={isPathActive('/icons') ? 'nav-item active' : 'nav-item'}>
            <div className={state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('iconsMenuOpen')} data-toggle="Accordion.collapse">
              <i className="mdi mdi-contacts menu-icon"></i>
              <span className="menu-title"><Trans>Icons</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Accordion.Collapse eventKey={state.iconsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link'} to="/icons/mdi"><Trans>Material</Trans></Link></li>
              </ul>
            </Accordion.Collapse>
          </li>
          <li className={isPathActive('/charts') ? 'nav-item active' : 'nav-item'}>
            <div className={state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('chartsMenuOpen')} data-toggle="Accordion.collapse">
              <i className="mdi mdi-chart-bar menu-icon"></i>
              <span className="menu-title"><Trans>Charts</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Accordion.Collapse eventKey={state.chartsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link'} to="/charts/chart-js"><Trans>Chart Js</Trans></Link></li>
              </ul>
            </Accordion.Collapse>
          </li>
          <li className={isPathActive('/user-pages') ? 'nav-item active' : 'nav-item'}>
            <div className={state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('userPagesMenuOpen')} data-toggle="Accordion.collapse">
              <i className="mdi mdi-lock menu-icon"></i>
              <span className="menu-title"><Trans>User Pages</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Accordion.Collapse eventKey={state.userPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/login-1"><Trans>Login</Trans></Link></li>
                <li className="nav-item"> <Link className={isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/register-1"><Trans>Register</Trans></Link></li>
                <li className="nav-item"> <Link className={isPathActive('/user-pages/lockscreen') ? 'nav-link active' : 'nav-link'} to="/user-pages/lockscreen"><Trans>Lockscreen</Trans></Link></li>
              </ul>
            </Accordion.Collapse>
          </li>
          <li className={isPathActive('/error-pages') ? 'nav-item active' : 'nav-item'}>
            <div className={state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('errorPagesMenuOpen')} data-toggle="Accordion.collapse">
              <i className="mdi mdi-security menu-icon"></i>
              <span className="menu-title"><Trans>Error Pages</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Accordion.Collapse eventKey={state.errorPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-500">500</Link></li>
              </ul>
            </Accordion.Collapse>
          </li>
          <li className={isPathActive('/general-pages') ? 'nav-item active' : 'nav-item'}>
            <div className={state.generalPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('generalPagesMenuOpen')} data-toggle="Accordion.collapse">
              <i className="mdi mdi-medical-bag menu-icon"></i>
              <span className="menu-title"><Trans>General Pages</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Accordion.Collapse eventKey={state.generalPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/general-pages/blank-page') ? 'nav-link active' : 'nav-link'} to="/general-pages/blank-page"><Trans>Blank Page</Trans></Link></li>
              </ul>
            </Accordion.Collapse>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="http://bootstrapdash.com/demo/purple-react-free/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <span className="menu-title"><Trans>Documentation</Trans></span>
              <i className="mdi mdi-file-document-box menu-icon"></i>
            </a>
          </li> */}
        </ul>
      </Accordion>
    </nav>
  );
};

export default Sidebar;
