import { STATUSES } from "enumerations";
import { Children } from "react";
import { Dropdown } from "react-bootstrap";

const TableStatuses = ({ param, value, onClick, slice, data }) => {
	let foundStatus = data.find((status) => status.value === value);
	console.log("Status", { data });
	return (
		<Dropdown>
			<Dropdown.Toggle
				id="dropdown-basic"
				className="btn-sm filter-sort mr-3"
				variant={`${
					foundStatus?.color
					// ?
					//   // "secondary"
					//   "danger"
					//   : status === "ACTIVE" ? "success" : status === "DELETE" ? "danger" : status === "BLOCK" ? "warning" : "dark"
				}
        `}>
				<span>{foundStatus?.label}</span>
			</Dropdown.Toggle>

			<Dropdown.Menu className="dropDown-menu-custom">
				{Children.toArray(
					data.map((item) => (
						<Dropdown.Item
							key={item.label + "_STATUS"}
							onClick={item.value === value ? "" : () => onClick({ data: [param], status: item })}
							className="dropDown-item-custom"
							active={item.value === value}>
							{item?.label}
						</Dropdown.Item>
					))
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default TableStatuses;
