import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PARENT_ROUTE_NAME, PARENT_COMPONENT_NAMES } from "../enum";
import { CATEGORY_DATA } from "enumerations";
import HotelSelector from "containers/Hotel/HotelSelector";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";

const Copy = () => {
	const { t } = useTranslation();

	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(PARENT_ROUTE_NAME, routes), []);

	// ----------------------------------------------------------------------------- Fetching Functions

	const navigation = useNavigate();
	let { id } = useParams();
	// console.log({ id, hotel });
	const URL_ID = id ? id : "";
	// console.log({ isAll });

	const creating = async (params) => await axiosClient().post(api[API_NAME].copy + "/" + id, params);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});

	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		navigation(newRoute[API_NAME].base + newRoute[API_NAME].list + "/" + id);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: creating,
		invalidQuery: `create_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const values = getValues();
		const requestData = {
			exceptCategories: values.exceptCategories?.map((x) => x.value),
			targetHotel: values.targetHotel?.value,
		};
		console.log({ requestData, values });
		mutate(requestData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: t(`backAuthor`, { author: t(COMPONENT_NAMES[0]) }),
		link: newRoute[API_NAME].list + "/" + id,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: PARENT_COMPONENT_NAMES[0], link: newRoute.base },
		{ title: COMPONENT_NAMES[0], link: newRoute[API_NAME].base + newRoute[API_NAME].list + "/" + id },
		{
			title: "copy",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects
	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);

	return (
		<div>
			<PageTitle title={`copy  ${COMPONENT_NAMES[0]}`} button={button} />
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<HotelSelector
												{...{
													Controller,
													name: fieldNames.targetHotel,
													register,
													label: "hotel",
													errors,
													control,
												}}
											/>

											<FieldSelect
												isMulti
												{...{
													Controller,
													name: fieldNames.exceptCategories,
													register,
													label: "exceptCategories",
													errors,
													control,
													options: CATEGORY_DATA,
													isMulti: true,
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Copy;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
