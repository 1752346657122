import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { STATUSES, USER_TYPES, USER_TYPES_OBJECT } from "enumerations";
import { useTranslation } from "react-i18next";
import CheckBox from "components/CheckBox";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";

const Add = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigation = useNavigate();

	const [permissionsOption, setPermissionsOption] = useState([]);
	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const previousQuery = location?.state?.query;

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);

	let { id } = useParams();
	const URL_ID = id ? id : "";

	const statusOptions = STATUSES.slice(0, 3);

	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation()),
		mode: "onSubmit",
	});

	const values = getValues();
	const watchUserType = watch(fieldNames.userType);
	const watchPermissions = watch(fieldNames.permissions);
	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		const params = new URLSearchParams(previousQuery || {});
		navigation(newRoute.base + newRoute.list + `?${params.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		// let selectedStatus = statusOptions.find((item) => item.value === params.status);

		reset({
			...params,
			userType: USER_TYPES_OBJECT[params.userType],
			// permissions: selectConvert(params?.permissions),
			// status: selectedStatus,
		});
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING PERMISSIONS ----------------------------------------- //
	const getPermissions = async () => await axiosClient().get(api["permission"].userType + "/" + watchUserType?.value);

	const onSuccessPermission = ({ data }) => {
		setPermissionsOption(selectConvert(data?.result));
	};
	const permissionQuery = useQueryCustom({
		name: `permission_BY_USER_TYPE_${watchUserType?.value}`,
		url: getPermissions,
		onSuccess: onSuccessPermission,
		enabled: !!watchUserType?.value,
	});
	const permissionsArray = useMemo(() => {
		let data = permissionQuery?.data?.data?.permissions;
		if (!data) return [];

		// Convert the data object into the desired array format
		const result = Object.keys(data).map((key) => {
			const innerKeys = Object.keys(data[key]);
			const items = innerKeys?.map((innerKey) => {
				const isObjectValue = Object.keys(data[key][innerKey]);
				let itemsY = false;
				if (isObjectValue?.length) {
					let innerKeysY = Object.keys(data[key][innerKey]);
					itemsY = innerKeysY?.map((innerKeyZ) => {
						return {
							label: innerKeyZ,
							value: false,
						};
					});
					// console.log({ innerKeysY, itemsY });
				}

				return {
					label: innerKey,
					value: itemsY,
				};
			});
			return {
				label: key,
				isDisabled: false, // Assuming isDisabled is constant for all items
				items,
			};
		});

		return result;

		// let data=[]
		// for (const key in object) {
		//   if (Object.hasOwnProperty.call(object, key)) {
		//     const curr = object[key];
		//     data.push()
		//   }
		// }
	}, [permissionQuery, dataById]);
	// ----------------------------------------------------------------------------- Functions
	function selectConvert(data) {
		return data?.map((param) => ({ label: param.name || param.title, value: param._id }));
	}
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const values = getValues();
		let permissions = {};
		for (const key in values.permissions) {
			let data = [];
			if (Object.hasOwnProperty.call(values.permissions, key)) {
				const objParent = values.permissions[key];
				for (const keyChild in objParent) {
					if (Object.hasOwnProperty.call(objParent, keyChild)) {
						const objChild = objParent[keyChild];
						if (objChild) data.push(keyChild);
						// console.log({ keyChild, objChild }, "onSubmit");
					}
				}
				// console.log({ key, objParent, data }, "onSubmit");

				if (data?.length) {
					permissions[key] = objParent;
				}
			}
		}

		console.log({ permissions }, "onSubmit");

		const requestData = {
			...values,
			userType: values.userType?.value,
			permissions,
			isDefault: !!values?.isDefault,
			// permissions: { activities: {} }
		};
		console.log({ requestData });
		mutate(requestData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: t(`backAuthor`, { author: t(COMPONENT_NAMES[0]) }),
		link: newRoute.base + newRoute.list,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base.base },
		{ title: t(COMPONENT_NAMES[0]), link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.result?.name}` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);

	// useEffect(() => {
	//   if (values?.type?.value === "USER") {
	//     setTypeState(true);
	//   }
	// }, [values]);
	// ----------------------------------------------------------------------------- Log
	console.log({ dataById, permissionQuery, permissionsArray, permissionsOption, values }, "Role-Add =>index.js");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	const handleSelectAllPermission = () => {
		permissionsArray?.map((x, i) => {
			x?.items?.forEach((a) => setValue(`${[fieldNames.permissions]}.${x.label}.${a.label}`, true));
		});
	};

	return (
		<div>
			<PageTitle
				title={URL_ID ? t(`editAuthor`, { author: t(COMPONENT_NAMES[0]) }) : t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) })}
				button={button}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldText
												require
												{...{
													name: fieldNames.name,
													register,
													placeholder: "name",
													label: "name ",
													errors,
												}}
											/>

											<FieldSelect
												require
												{...{
													name: fieldNames.userType,
													register,
													label: "userType",
													errors,
													control,
													options: USER_TYPES,
												}}
											/>

											<CheckBox
												{...{
													name: fieldNames.isDefault,
													register,
													label: "isDefault",
													errors,
													control,
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						{
							// ["admin", "super_admin"].includes(watchUserType?.value)
							true ? (
								<div className="col-12 border p-4 mt-4">
									<div className="">
										<div className="d-flex b-1">
											<h2 className="text-nowrap">{`Permissions (${watchUserType?.label})`} </h2>
											<div className="ml-auto"></div>
											<Button onClick={handleSelectAllPermission}>{"select All"}</Button>
										</div>
										<div className="mt-5">
											<div className="col-12">
												<div className="basic-form"></div>

												<Accordion defaultActiveKey="0">
													{permissionsArray?.map((x, i) => {
														// if (watchUserType?.value != "super_admin" && x.label == "users") return;
														const values = getValues();

														let selectAll = true;

														// values?.[fieldNames.permissions]?.[a.label]?.value
														x?.items?.forEach((a) => (selectAll = selectAll && values?.[fieldNames.permissions]?.[x.label]?.[a.label]));

														const handleSelectAll = () => {
															x?.items?.forEach((a) => setValue(`${[fieldNames.permissions]}.${x.label}.${a.label}`, selectAll ? false : true));
														};
														console.log({ values, x, selectAll }, { permission: values?.[fieldNames.permissions]?.[x.label] });

														// const isArray = x?.items?.filter((y) => y?.value?.length)
														// const items = x?.items?.filter((y) => !y?.value?.length)
														// console.log({ isArray, items });

														return (
															<Accordion.Item eventKey={i} key={x.label}>
																<Accordion.Header>
																	{x.label}
																	{selectAll ? "(Full)" : ""}
																</Accordion.Header>
																<Accordion.Body>
																	<Row>
																		<Col>
																			<Button onClick={handleSelectAll} className={"d-flex align-items-center"} size="sm">
																				<div className={"gallery-add-title"}>{selectAll ? "Remove all" : "Select all"}</div>
																			</Button>
																		</Col>
																		{x?.items?.map((y) => {
																			return (
																				<Col key={y.label}>
																					<CheckBox
																						{...{
																							Controller,
																							name: `${[fieldNames.permissions]}.${x.label}.${y.label}`,
																							register,
																							label: y.label,
																							control,
																						}}
																					/>
																				</Col>
																			);
																		})}
																	</Row>
																</Accordion.Body>
															</Accordion.Item>
														);
													})}
												</Accordion>
											</div>
										</div>
									</div>
								</div>
							) : (
								""
							)
						}
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} disabled={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;
