export const convertorArrayOption = (array, object) => {
	for (const key in array) {
		if (Object.hasOwnProperty.call(array, key)) {
			const data = array[key];
			object[data.value] = data;
		}
	}
};

export const convertBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return "0 Bytes";
	const kb = 1024;
	const correctDecimals = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(kb));

	return parseFloat((bytes / Math.pow(kb, i)).toFixed(correctDecimals)) + " " + sizes[i];
};

export function convertToTimeFormat(number) {
	if (number < 0 || number > 23) {
		return "Invalid input! Please enter a number between 0 and 23.";
	}
	const formattedNumber = String(number).padStart(2, "0");
	const timeFormat = `${formattedNumber}:00`;
	return timeFormat;
}

export function convertTimeTo24HourFormat(timeString) {
	console.log({ timeString });
	if (!timeString) return;
	const split = timeString.split(" ");
	// console.log("resData ===>", { split });
	const timeMoment = split[0]?.split?.(":");
	// console.log("resData ===>", { timeMoment });
	let hours = timeMoment?.[0];
	let minutes = timeMoment?.[1];
	if (String(hours)?.length == 1) hours = "0" + hours;
	if (String(minutes)?.length == 1) minutes = "0" + minutes;
	// console.log("resData ===>", { hours, minutes, timeMoment });
	let time24Hour;
	if (timeString?.includes("PM") && +hours < 12) {
		time24Hour = `${+hours + 12}:${minutes}`;
	} else if (timeString?.includes("AM") && +hours === 12) {
		time24Hour = `00:${minutes}`;
	} else {
		time24Hour = `${hours}:${minutes}`;
	}
	return time24Hour;
}
