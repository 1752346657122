import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import configureStore from "lib/reduxConfig";
import { ToastContainer } from "react-toastify";
//----------------------------------------------------------------------- CSS IMPORTS
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
});

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<I18nextProvider i18n={i18n}>
				<Provider store={store}>
					<App />
					<ToastContainer
						rtl={false}
						position="top-right"
						autoClose={5000}
						hideProgressBar={false}
						closeOnClick={true}
						pauseOnHover={true}
						draggable={true}
						progress={undefined}
					/>
				</Provider>
			</I18nextProvider>
		</QueryClientProvider>
	</BrowserRouter>
);

serviceWorker.unregister();
