import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
	amount: "amount",
	invoice: "invoice",
	hotelId: "hotelId",
};

export const validation = (URL_ID) =>
	yup.object().shape({
		[fieldNames.amount]: yup
			.number()
			.nullable()
			.typeError(ERRORS.pleaseEnterYour(fieldNames.amount))
			.required(ERRORS.pleaseEnterYour(fieldNames.amount))
			.label(fieldNames.amount),
		[fieldNames.hotelId]: yup.mixed().required(ERRORS.pleaseEnterYour(fieldNames.hotelId)).label(fieldNames.hotelId),
		[fieldNames.invoice]: yup.mixed().required(ERRORS.pleaseEnterYour(fieldNames.invoice)).label(fieldNames.invoice),
	});
