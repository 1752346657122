import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import compareTranslate from "utils/compareTranslate";

const FieldFile = ({
	control,
	name,
	label,
	valid,
	register,
	errors,
	require,
	type,
	error,
	placeholder,
	setState,
	onChange,
	disables,
	end,
	multiple,
	accept,
	...other
}) => {
	const [show, setShow] = useState(false);
	let newRegister = register ? register(name) : {};

	const [t] = useTranslation();

	const getFileSizes = (bytes) => {
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		const result = {};
		sizes.forEach((size, index) => {
			result[size] = +(bytes / Math.pow(1024, index)).toFixed(2);
		});
		return result;
	};

	return (
		<Form.Group className="form-group ">
			<div className={"d-flex justify-content-between"}>
				{label ? (
					<label htmlFor={name}>
						{!disables?.translate?.label ? compareTranslate(t, label) : label}
						{require ? " *" : ""}
					</label>
				) : (
					""
				)}
			</div>
			<div className="input-wrapper d-flex">
				{control ? (
					<Controller
						control={control}
						ref={newRegister}
						name={name}
						render={({ field: { onChange: change, value } }) => {
							console.log({ value });
							function handleChange(e) {
								const selectedFile = e.target.files?.[0];

								if (!selectedFile) return;
								// -------------------------- variables

								const size = getFileSizes(selectedFile.size);
								const [format, type] = selectedFile?.type?.split("/");

								// -------------------------- log

								console.log("* * * UploadImage :", { size, selectedFile, format, type });

								// -------------------------- checker
								if (selectedFile) {
									const isImage = format?.includes("image");
									const isVideo = format?.includes("video");
									const fileMaxSize = 50;
									if (fileMaxSize < size) return toast.error(`File size exceeds the maximum allowed limit of ${fileMaxSize}MB.`);

									// fileRef.current.value = "";

									onChange?.(selectedFile);
									change(selectedFile);
								}
							}
							return (
								// <Form.Control
								// 	id={name}
								// 	name={name}
								// 	type={type === "password" ? (show ? "text" : "password") : type}
								// 	placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label}
								// 	onChange={handleChange}
								// 	value={value}
								// 	{...other}
								// />
								<div className="custom-file cursor-pointer">
									<Form.Control
										multiple
										type="file"
										className="position-absolute visibility-hidden"
										id={name}
										accept={accept}
										{...other}
										onChange={handleChange}
									/>
									<label className="custom-file-label cursor-pointer" htmlFor={name}>
										{value?.name || "Upload File"}
									</label>
								</div>
							);
							// console.log({ onChange, value, options },'');
							// return <Select className="custom-selector" placeholder={label} options={newOptions} value={value} onChange={handleChange} {...other} />;
						}}
					/>
				) : (
					<Form.Control
						id={name}
						name={name}
						type={type === "password" ? (show ? "text" : "password") : type}
						placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label}
						onChange={onChange}
						{...other}
						{...newRegister}
					/>
				)}

				{type === "password" && (
					<i className={`${show ? "mdi mdi-eye-off" : "mdi mdi-eye"} cursor-pointer text-muted eye-wrapper`} onClick={() => setShow(!show)}></i>
				)}
				{end}
			</div>

			{errors?.[name] ? (
				<div className={`error-text mt-2  `}>{!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}</div>
			) : (
				""
			)}
			{error ? <div className={`error-text mt-2  `}>{!disables?.translate?.error ? compareTranslate(t, error?.message) : error?.message}</div> : ""}
		</Form.Group>
	);
};
export default FieldFile;
