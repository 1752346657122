import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { tableData } from "./tableInfo";

const RoleList = () => {
	const { t } = useTranslation();

	const [checkList, setCheckList] = useState();
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance
	const button = { title: `Add ${COMPONENT_NAMES[0]}`, link: newRoute.base + newRoute.add };
	const QueryString = useQueryString({ sort: "desc" });
	const { page, limit } = QueryString;
	const dispatch = useDispatch();

	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};

	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
	});

	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		actionsOperation({ data, status: status });
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base.base },
		{ title: t(COMPONENT_NAMES[0]), link: newRoute.base },
		{ title: "Details" },
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	const tableOptions = tableData({ onClick: clickAction, newRoute });

	//  ----------------------------------------- LOGS ----------------------------------------- //

	return (
		<div>
			<PageTitle title={t("authorDetails", { author: t(COMPONENT_NAMES[0]) })} breadcrumbs={breadcrumbs} button={button} />
			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: "search",
						defaultValue: QueryString.search,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data.count,
					pagination: {
						activePage: page,
						pages: Math.ceil(data.count / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
				}}
			/>
		</div>
	);
};

export default RoleList;
