import TableAction from "components/TableAction";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
import { Button } from "react-bootstrap";
export const tableData = ({ onClick, newRoute, handleContract, ...props }) => [
	// {
	//   header: "qrCode",
	//   Component: ({ param }) => {
	//     return (
	//       <>
	//         <span className="d-block mb-1">
	//           <img src={param?.qrCode?.path || PLACEHOLDER_IMAGE} />
	//         </span>
	//       </>
	//     );
	//   },
	// },
	// {
	//   header: "user",
	//   Component: ({ param }) => param?.user?.email || "Unknown",
	// },
	// {
	//   header: "name",
	//   Component: ({ param }) => param.name || "Unknown",
	// },
	{
		header: "user",
		Component: ({ param }) => {
			const { base, edit } = routes.user;
			return param.user ? (
				<Link to={`${base + edit}/${param.user.id}`} target="_blank">
					{" "}
					{param.user?.name}{" "}
				</Link>
			) : (
				"Unknown"
			);
		},
	},

	// {
	//   header: "branch",
	//   Component: ({ param }) => param.branch?.name || "Unknown",
	// },
	// {
	//   header: "branch",
	//   Component: ({ param }) => {
	//     const { base, edit } = routes.branch;
	//     return param.branch ? (
	//       <Link to={`${base + edit}/${param.branch.id}`} target="_blank">
	//         {" "}
	//         {param.branch?.name}{" "}
	//       </Link>
	//     ) : (
	//       "Unknown"
	//     );
	//   },
	// },
	{
		header: "productsIntegrationCodes",
		Component: ({ param }) => param.productsIntegrationCodes?.join(" , "),
	},
	{
		header: "paymentPeriod",
		Component: ({ param }) => param.paymentPeriod,
	},
	{
		header: "commercialTitle",
		Component: ({ param }) => param.commercialTitle || "Unknown",
	},
	// {
	//   header: "currentNotWorkingReason",
	//   Component: ({ param }) => param.currentNotWorkingReason || "Unknown"
	// },
	// {
	//   header: "startTime",
	//   Component: ({ param }) => param.startTime || "Unknown"
	// },
	// {
	//   header: "endTime",
	//   Component: ({ param }) => param.endTime || "Unknown"
	// },
	{
		header: "address",
		Component: ({ param }) => param.address || "Unknown",
	},
	{
		header: "panelName",
		Component: ({ param }) => param.panelName || "Unknown",
	},
	{
		header: "taxAdministration",
		Component: ({ param }) => param.taxAdministration || "Unknown",
	},
	{
		header: "taxNo",
		Component: ({ param }) => param.taxNo || "Unknown",
	},
	{
		header: "createdAt",
		Component: ({ param }) => isoToDate(param.createdAt),
	},
	// {
	//   header: "Status",
	//   Component: ({ param }) => <TableStatus {...{ param, onClick, slice: { second: 5 } }} />,
	// },
	// {
	// 	header: "contract",
	// 	Component: ({ param }) => (
	// 		<Button variant="outline" size="sm" onClick={handleContract}>
	// 			{"download"}
	// 		</Button>
	// 	),
	// },
	{
		header: "Actions",
		Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} {...props} />,
	},
];
