import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import axiosClient from "lib/axiosConfig";
import api from "operations/network/api";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import { useQueryCustom } from "utils/useQueryCustom";
import { changePriceFormat } from "utils/calculators";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import routes from "operations/routing/routes";
import { useSelector } from "react-redux";

// import "react-datepicker/dist/react-datepicker.css";

const Dashboard = () => {
	const adminState = useSelector((state) => state.admin);
	const isAdmin = adminState.data.userType?.includes("admin");

	const dispatch = useDispatch();
	const [state, setState] = useState({
		trafficData: {},
		trafficOptions: {
			responsive: true,
			animation: {
				animateScale: true,
				animateRotate: true,
			},
			legend: false,
		},
	});

	const getting = async () => await axiosClient().get(api.dashboard);
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `dashboard_get`,
		url: getting,
	});
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [{ title: "Dashboard" }];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);
	const [t] = useTranslation();
	console.log({ data });
	// useEffect(() => {
	//   let ctx = document.getElementById("visitSaleChart").getContext("2d");
	//   let gradientBar1 = ctx.createLinearGradient(0, 0, 0, 181);
	//   gradientBar1.addColorStop(0, "rgba(218, 140, 255, 1)");
	//   gradientBar1.addColorStop(1, "rgba(154, 85, 255, 1)");

	//   let gradientBar2 = ctx.createLinearGradient(0, 0, 0, 360);
	//   gradientBar2.addColorStop(0, "rgba(54, 215, 232, 1)");
	//   gradientBar2.addColorStop(1, "rgba(177, 148, 250, 1)");

	//   let gradientBar3 = ctx.createLinearGradient(0, 0, 0, 300);
	//   gradientBar3.addColorStop(0, "rgba(255, 191, 150, 1)");
	//   gradientBar3.addColorStop(1, "rgba(254, 112, 150, 1)");

	//   let gradientdonut1 = ctx.createLinearGradient(0, 0, 0, 181);
	//   gradientdonut1.addColorStop(0, "rgba(54, 215, 232, 1)");
	//   gradientdonut1.addColorStop(1, "rgba(177, 148, 250, 1)");

	//   let gradientdonut2 = ctx.createLinearGradient(0, 0, 0, 50);
	//   gradientdonut2.addColorStop(0, "rgba(6, 185, 157, 1)");
	//   gradientdonut2.addColorStop(1, "rgba(132, 217, 210, 1)");

	//   let gradientdonut3 = ctx.createLinearGradient(0, 0, 0, 300);
	//   gradientdonut3.addColorStop(0, "rgba(254, 124, 150, 1)");
	//   gradientdonut3.addColorStop(1, "rgba(255, 205, 150, 1)");

	//   const active = data?.activeHotelsCount;

	//   const inactive = data?.inActiveHotelsCount;

	//   const total = active + inactive;
	//   const activePercent = (100 * (total - inactive)) / total;
	//   const inactivePercent = (100 * (total - active)) / total;

	//   console.log({ activePercent, inactivePercent });

	//   const trafficData = {
	//     datasets: [
	//       {
	//         data: [activePercent.toFixed(2), inactivePercent.toFixed(2)],
	//         backgroundColor: [gradientdonut1, gradientdonut3],
	//         hoverBackgroundColor: [gradientdonut1, gradientdonut3],
	//         borderColor: [gradientdonut1, gradientdonut3],
	//         legendColor: [gradientdonut1, gradientdonut3],
	//       },
	//     ],

	//     // These labels appear in the legend and in the tooltips when hovering different arcs
	//     labels: ["Active", "Inactive"],
	//   };

	//   setState((p) => ({ ...p, trafficData }));
	// }, [data]);

	return (
		<div>
			<div className="page-header">
				<h3 className="page-title">
					<span className="page-title-icon bg-gradient-primary text-white mr-2">
						<i className="mdi mdi-home"></i>
					</span>{" "}
					{t("dashboard")}
				</h3>
				<nav aria-label="breadcrumb">
					<ul className="breadcrumb">
						<li className="breadcrumb-item active" aria-current="page">
							<span></span>
							{t("overview")} <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
						</li>
					</ul>
				</nav>
			</div>
			<div className="row">
				<div className="col-md-4 stretch-card grid-margin">
					<div className="card bg-gradient-info card-img-holder text-white">
						<div className="card-body pb-2">
							<img src={require("assets/images/dashboard/circle.png")} className="card-img-absolute" alt="circle" />
							<h4 className="font-weight-normal mb-3">
								{isAdmin ? t("dailyOrders") : t("dailySalesAchievements")} <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
							</h4>
							<ul className="ml-0 pl-0 mt-4">
								<li className="d-flex align-items-center">
									<span className="legend-dots bg-info"></span>
									<span className="">{isAdmin ? t("totalOrders") : t("totalOrderCount")}</span>
									<span className="ml-auto">{changePriceFormat(data?.dailyOrders?.total)}</span>
								</li>
								<li className="d-flex align-items-center">
									<span className="legend-dots bg-success"></span>
									<span>{isAdmin ? t("allOrders") : t("totalRevenue")}</span>
									<span className="ml-auto">{changePriceFormat(data?.dailyOrders?.totalAmount)}</span>
								</li>
								{/* <li className="d-flex align-items-center">
                  <span className="legend-dots bg-danger"></span>
                  <span>{t("commission")}</span>
                  <span className="ml-auto">
                    {changePriceFormat(data?.dailyOrders?.commission)}
                  </span>
                </li> */}
							</ul>
						</div>
					</div>
				</div>

				<div className="col-md-4 stretch-card grid-margin">
					<div className="card bg-gradient-success card-img-holder text-white">
						<div className="card-body pb-2">
							<img src={require("assets/images/dashboard/circle.png")} className="card-img-absolute" alt="circle" />
							<h4 className="font-weight-normal mb-3">
								{isAdmin ? t("monthlyOrders") : t("monthlySalesAchievements")} <i className="mdi mdi-diamond mdi-24px float-right"></i>
							</h4>
							<ul className="ml-0 pl-0 mt-4">
								<li className="d-flex align-items-center">
									<span className="legend-dots bg-info"></span>
									<span className="">{isAdmin ? t("totalOrders") : t("totalOrderCount")}</span>
									<span className="ml-auto">{changePriceFormat(data?.monthlyOrders?.total)}</span>
								</li>
								<li className="d-flex align-items-center">
									<span className="legend-dots bg-success"></span>
									<span>{isAdmin ? t("allOrders") : t("totalRevenue")}</span>
									<span className="ml-auto">{changePriceFormat(data?.monthlyOrders?.totalAmount)}</span>
								</li>
								{/* <li className="d-flex align-items-center">
                  <span className="legend-dots bg-danger"></span>
                  <span>{t("commission")}</span>
                  <span className="ml-auto">
                    {changePriceFormat(data?.monthlyOrders?.commission)}
                  </span>
                </li> */}
							</ul>
						</div>
					</div>
				</div>

				<div className="col-md-4 stretch-card grid-margin">
					<div className="card bg-gradient-danger card-img-holder text-white">
						<div className="card-body pb-2">
							<img src={require("assets/images/dashboard/circle.png")} className="card-img-absolute" alt="circle" />
							<h4 className="font-weight-normal mb-3">
								{isAdmin ? t("allOrders") : t("overallSalesAchievements")} <i className="mdi mdi-diamond mdi-24px float-right"></i>
							</h4>

							<ul className="ml-0 pl-0 mt-4">
								<li className="d-flex align-items-center">
									<span className="legend-dots bg-info"></span>
									<span className="">{isAdmin ? t("totalOrders") : t("totalOrderCount")}</span>
									<span className="ml-auto">{changePriceFormat(data?.allOrders?.total)}</span>
								</li>
								<li className="d-flex align-items-center">
									<span className="legend-dots bg-success"></span>
									<span>{isAdmin ? t("allOrders") : t("totalRevenue")}</span>
									<span className="ml-auto">{changePriceFormat(data?.allOrders?.totalAmount)}</span>
								</li>
								{isAdmin ? (
									<li className="d-flex align-items-center">
										<span className="legend-dots bg-danger"></span>
										<span>{t("commission")}</span>
										<span className="ml-auto">{changePriceFormat(data?.allOrders?.commission)}</span>
									</li>
								) : (
									""
								)}
							</ul>
							{/* <h2 className="mb-5">$ 15,0000</h2>
              <h6 className="card-text">Increased by 60%</h6> */}
						</div>
					</div>
				</div>
			</div>
			{isAdmin ? (
				<div className="row">
					{/* <div className="col-md-5 grid-margin stretch-card">
            <div className="card widget">
              <div className="card-body">
                <h4 className="card-title">Hotels status</h4>
                <Doughnut
                  data={state.trafficData}
                  options={state.trafficOptions}
                />
                <div
                  id="traffic-chart-legend"
                  className="rounded-legend legend-vertical legend-bottom-left pt-4"
                >
                  <ul>
                    <li>
                      <span className="legend-dots bg-info"></span>
                      <Link
                        to={
                          routes.hotel.base +
                          routes.hotel.list +
                          `?isActive=true`
                        }
                      >
                        ACTIVE HOTELS
                      </Link>
                      <span className="float-right">
                        {data?.activeHotelsCount}
                      </span>
                    </li>
                    <li>
                      <span className="legend-dots bg-danger"></span>
                      <Link
                        to={
                          routes.hotel.base +
                          routes.hotel.list +
                          `?isActive=false`
                        }
                      >
                        INACTIVE HOTELS
                      </Link>

                      <span className="float-right">
                        {data?.inActiveHotelsCount}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}

					{/* <div className="col-md-4 grid-margin stretch-card">
          <div className="card widget">
            <div className="card-body">
              <h4 className="card-title">{t("allOrders")}</h4>
              <div
                id="traffic-chart-legend"
                className="rounded-legend legend-vertical legend-bottom-left pt-4"
              >
                { }
                <ul>
                  <li className="d-flex align-items-center">
                    <span className="legend-dots bg-info"></span>
                    <span className="">{isAdmin?t("totalOrders") :t("totalOrderCount")}</span>
                    <span className="ml-auto">{changePriceFormat(data?.allOrders?.total)}</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <span className="legend-dots bg-success"></span>
                    <span>
                      {isAdmin?t("allOrders") :t("totalRevenue")}
                    </span>
                    <span className="ml-auto">{changePriceFormat(data?.allOrders?.totalAmount)}</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <span className="legend-dots bg-danger"></span>
                    <span>
                      {t("commission")}
                    </span>
                    <span className="ml-auto">{changePriceFormat(data?.allOrders?.commission)}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}

					{/* <div className="col-md-4 grid-margin stretch-card">
          <div className="card widget">
            <div className="card-body">
              <h4 className="card-title">{t("dailyOrders")}</h4>
              <div
                id="traffic-chart-legend"
                className="rounded-legend legend-vertical legend-bottom-left pt-4"
              >
                <ul>
                  <li className="d-flex align-items-center">
                    <span className="legend-dots bg-info"></span>
                    <span className="">{isAdmin?t("totalOrders") :t("totalOrderCount")}</span>
                    <span className="ml-auto">{changePriceFormat(data?.dailyOrders?.total)}</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <span className="legend-dots bg-success"></span>
                    <span>
                      {isAdmin?t("allOrders") :t("totalRevenue")}
                    </span>
                    <span className="ml-auto">{changePriceFormat(data?.dailyOrders?.totalAmount)}</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <span className="legend-dots bg-danger"></span>
                    <span>
                      {t("commission")}
                    </span>
                    <span className="ml-auto">{changePriceFormat(data?.dailyOrders?.commission)}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}

					{/* <div className="col-md-4 grid-margin stretch-card">
          <div className="card widget">
            <div className="card-body">
              <h4 className="card-title">{t("monthlyOrders")}</h4>
              <div
                id="traffic-chart-legend"
                className="rounded-legend legend-vertical legend-bottom-left pt-4"
              >
                <ul>
                  <li className="d-flex align-items-center">
                    <span className="legend-dots bg-info"></span>
                    <span className="">{isAdmin?t("totalOrders") :t("totalOrderCount")}</span>
                    <span className="ml-auto">{changePriceFormat(data?.monthlyOrders?.total)}</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <span className="legend-dots bg-success"></span>
                    <span>
                      {isAdmin?t("allOrders") :t("totalRevenue")}
                    </span>
                    <span className="ml-auto">{changePriceFormat(data?.monthlyOrders?.totalAmount)}</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <span className="legend-dots bg-danger"></span>
                    <span>
                      {t("commission")}
                    </span>
                    <span className="ml-auto">{changePriceFormat(data?.monthlyOrders?.commission)}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
				</div>
			) : (
				""
			)}
			<canvas id="visitSaleChart" width="0" height="0"></canvas>
		</div>
	);
};

export default Dashboard;
