import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { ALLERGENS, ALLERGENS_ARRAY, API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import {
	DRINK_DATA,
	DRINK_DATA_OBJECT,
	INVOICE_DATA,
	INVOICE_DATA_OBJECT,
	LANGUAGE_DATA,
	LANGUAGE_DATA_OBJECT,
	ROLES,
	// PRODUCT_STATUSES,
	// PRODUCT_STATUSES_OBJECT,
	STATUSES,
	STATUSES_OBJECT,
} from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import BranchSelector, { convertBranchData } from "containers/Branch/BranchSelector";
import CategorySelector, { convertCategoryData } from "containers/Category/CategorySelector";
import GalleryComponent from "components/gallery/GalleryComponent";
import LanguageSelector from "containers/Language/LanguageSelector";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const Add = ({ copy }) => {
	const { t } = useTranslation();

	const navigation = useNavigate();
	let { id } = useParams();
	const URL_ID = id ? id : "";
	const location = useLocation();
	const previousQuery = location?.state?.query;
	const previousQueryParams = new URLSearchParams(previousQuery || {});
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const typeOptions = [
		{ label: "translation", value: "translation" },
		{ label: "Admin", value: "ADMIN" },
	];
	// console.log({ copy });
	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});

	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		navigation(newRoute.base + newRoute.list + `?${previousQueryParams.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID && !copy ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		console.log({ params });

		// handleSelectThumbnail({ _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title });

		const resetParam = {
			...params,
			category: convertCategoryData(params.category),
			branch: convertBranchData(params.branch),
			delivery: DRINK_DATA_OBJECT[params.delivery],
			isActive: STATUSES_OBJECT[params.isActive],
			// availabilityStatus: PRODUCT_STATUSES_OBJECT[params.availabilityStatus],
			allergies: params.allergies?.map((x) => ALLERGENS[x]),
			translations: params?.translations?.map((x) => ({
				translate_name: x.name,
				translate_shortDesc: x.shortDesc,
				// translate_desc: x.desc,
				translate_language: { label: x.language, value: x.language },
			})),
			// image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
		};
		console.log({ resetParam });
		reset(resetParam);
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const values = getValues();
		const {
			isCashActive,
			isCardActive,
			isOnlineActive,
			translations,
			images,
			translate_language,
			// isFastDelivery,
			isNewProduct,
			isRecommended,
			...params
		} = values;

		console.log({ values });
		const reqData = {
			...params,
			images: images?.path || images,
			isActive: values.isActive?.value,
			category: values.category?.value,
			branch: values.branch?.value,
			delivery: values.delivery?.value,
			allergies: values.allergies?.map((x) => x.value),
			// isFastDelivery: !!isFastDelivery,
			isNewProduct: !!isNewProduct,
			isRecommended: !!isRecommended,
			translations: translations?.map((x) => ({
				language: x.translate_language?.value || x.translate_language,
				name: x.translate_name,
				shortDesc: x.translate_shortDesc,
				// desc: x.translate_desc,
			})),
			branchProductName: values.branchProductName || null,
			// image: values.image._id,
		};
		console.log({ reqData });
		mutate(reqData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: t(`backAuthor`, { author: t(COMPONENT_NAMES[0]) }),
		link: newRoute.base + newRoute.list + `?${previousQueryParams.toString()}`,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: t(COMPONENT_NAMES[0]), link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.name} ` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.images, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	const defaultData = { translate_name: "" };

	const addNewtranslation = () => {
		const values = getValues();
		const newData = values[fieldNames.translations]
			? [...values[fieldNames.translations], { ...defaultData }]
			: [{ ...defaultData }, { ...defaultData }];
		console.log({ newData });
		setValue(fieldNames.translations, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};

	const isRecommendedState = watch(fieldNames.isRecommended);

	return (
		<div>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle
				title={URL_ID && !copy ? t(`editAuthor`, { author: t(COMPONENT_NAMES[0]) }) : t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) })}
				button={button}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldText
												require
												{...{
													name: fieldNames.name,
													register,
													placeholder: "name",
													label: "name",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.shortDesc,
													register,
													placeholder: "shortDesc",
													label: "shortDesc",
													errors,
												}}
											/>
											<FieldText
												// require
												{...{
													name: fieldNames.vat,
													register,
													placeholder: "vat",
													label: "vat",
													errors,
													type: "number",
												}}
											/>

											{/* <FieldText
                        require
                        {...{
                          name: fieldNames.priceList,
                          register,
                          placeholder: "priceList",
                          label: "priceList",
                          errors,
                        }}
                      /> */}
											{/* 
                      <FieldText
                        require
                        {...{
                          name: fieldNames.desc,
                          register,
                          placeholder: "desc",
                          label: "desc",
                          errors,
                        }}
                      /> */}

											{/* <FieldText
                        require
                        {...{
                          name: fieldNames.stock,
                          register,
                          placeholder: "10",
                          label: "stock",
                          errors,
                        }}
                      /> */}

											<FieldText
												require
												{...{
													name: fieldNames.price,
													register,
													placeholder: "10.00",
													label: "price",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.buyPrice,
													register,
													placeholder: "10.00",
													label: "buyPrice",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.integrationCode,
													register,
													placeholder: "integrationCode",
													label: "integrationCode",
													errors,
													// type: "number"
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.listOrderNumber,
													register,
													placeholder: "listOrderNumber",
													label: "listOrderNumber",
													errors,
													type: "number",
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.branchProductName,
													register,
													placeholder: "branchProductName",
													label: "branchProductName",
													errors,
												}}
											/>

											<FieldSelect
												{...{
													Controller,
													// name: fieldNames.availabilityStatus,
													name: fieldNames.isActive,
													register,
													label: "status",
													errors,
													control,
													options: STATUSES,
													// options: PRODUCT_STATUSES,
												}}
											/>
											<BranchSelector
												{...{
													// Controller,
													name: fieldNames.branch,
													register,
													label: "branch",
													errors,
													control,
												}}
											/>
											<CategorySelector
												{...{
													name: fieldNames.category,
													register,
													label: "category",
													errors,
													control,
												}}
											/>

											<CheckBox
												{...{
													control,

													name: fieldNames.isNewProduct,
													register,
													placeholder: "isNewProduct",
													label: "isNewProduct",
													errors,
												}}
											/>

											<CheckBox
												{...{
													control,
													name: fieldNames.isIdVerificationRequired,
													register,
													placeholder: "isIdVerificationRequired",
													label: "isIdVerificationRequired",
													errors,
												}}
											/>

											<CheckBox
												{...{
													control,
													name: fieldNames.medicine,
													register,
													placeholder: "medicine",
													label: "medicine",
													errors,
												}}
											/>

											{/* <CheckBox
                        {...{
                          // Controller,\
                          control,
                          name: fieldNames.isFastDelivery,
                          register,
                          label: "isFastDelivery",
                          errors,
                        }}
                      /> */}

											<CheckBox
												{...{
													// Controller,\
													control,
													name: fieldNames.isRecommended,
													register,
													label: "isRecommended",
													errors,
												}}
											/>

											{isRecommendedState ? (
												<>
													<FieldText
														require
														{...{
															name: fieldNames.kdv,
															register,
															placeholder: "kdv",
															label: "kdv",
															errors,
															type: "number",
														}}
													/>
													<FieldSelect
														{...{
															Controller,
															name: fieldNames.delivery,
															register,
															label: "delivery",
															errors,
															control,
															options: DRINK_DATA,
														}}
													/>
												</>
											) : (
												""
											)}

											<GalleryComponent
												isRequired
												{...{
													handleModal: handleGalleryModal,
													selectedPhoto: thumbnail,
													labe: "image",
													name: fieldNames.images,
													Controller,
													control,
													errors,
													register,
													description: `(Uploaded images must be square)`,
												}}
											/>
											{/* <CheckBox
                        {...{
                          // Controller,
                          control,

                          name: fieldNames.serviceCharge,
                          register,
                          label: "serviceCharge",
                          errors,
                        }}
                      /> */}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 mt-4">
							<div className="">
								{/* <div className="b-1">
                  <h4 className="">{"translations"}</h4>

                </div> */}
								<Row className="b-1">
									<Col lg={6}>
										<h2 className="">translations</h2>
									</Col>
									<Col lg={6} className="d-flex justify-content-end">
										<Button onClick={addNewtranslation} className={"d-flex align-items-center"} size="sm">
											<div className={"gallery-add-title"}>Add translate</div>
											<div className={"ml-1"}>+</div>
										</Button>
									</Col>
								</Row>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form"></div>
										<Controller
											name={fieldNames.translations}
											control={control}
											render={({ field: { onChange, value } }) => {
												console.log({ value });

												return Children.toArray(
													(value || [{ ...defaultData }]).map((locale, index) => {
														const values2 = getValues();

														function changeHandle(param) {
															console.log({ param });
															let values = value ? [...value] : [{ ...defaultData }];
															values[index][param.name] = param.value;
															onChange(values);
														}

														// function languageSelectHandler(e) {
														//   changeHandle({ name: "isFree", value: e });
														//   // console.log({ select: params.target.value });
														//   console.log({ e });
														// }

														// function reasonSelectHandler(e) {
														//   let target = e.target;
														//   changeHandle({ name: target.name, value: target.value });
														// }
														// function datePickerHandler(e) {
														//   changeHandle({ name: "startDate", value: e });
														//   let date = new Date(e.toISOString());
														//   // setStartDate(date);
														// }

														function handleChangeInput(e) {
															let target = e.target;
															console.log({ target });
															changeHandle({ name: target.name, value: target.checked || target.value });
														}
														function handleChangeSelect(e) {
															// let target = e.target;
															console.log({ e });
															changeHandle({ name: e.name, value: { label: e.label, value: e.value } });
														}
														function handleChangeCheckbox(e) {
															// let target = e.target;
															console.log({ e });
															changeHandle({ name: e.name, value: e.checked || e.value });
															// changeHandle({ name: target.name, value: target.checked || target.value });
														}

														const removeClick = (index) => {
															let values = getValues();
															if (!values[fieldNames.translations]) return;
															// newValues[fieldNames.episodes].splice(index, 1);
															const newValues = values[fieldNames.translations].filter((param, i) => i !== index);
															// console.log({ newValues });
															setValue(fieldNames.translations, newValues);
														};

														return (
															<Row className="translation-row shadow  p-3 my-3" key={value?.translate_email || index}>
																{index ? (
																	<>
																		<Col lg={6}>
																			<h5>translation{index + 1}</h5>
																		</Col>
																		<Col lg={6}>
																			<div className="d-flex">
																				<div className=" ml-auto">
																					<Button
																						color="danger"
																						className={"d-flex align-items-center"}
																						size="sm"
																						onClick={(e) => {
																							e.preventDefault();
																							removeClick(index);
																						}}>
																						remove
																					</Button>
																				</div>
																				<div className="mr-2"></div>
																				{value?.length === index + 1 ? (
																					<Button onClick={addNewtranslation} className={"d-flex align-items-center"} size="sm">
																						<div className={"gallery-add-title"}>Add translate</div>
																						<div className={"ml-1"}>+</div>
																					</Button>
																				) : (
																					""
																				)}
																			</div>
																		</Col>
																		<Col lg={12}>
																			<hr style={{ marginBottom: 0 }} />
																		</Col>
																	</>
																) : (
																	""
																)}
																{/* <Col md={12} lg={5}>
                                  <FormSelectDefaultWithControl change={languageSelectHandler} value={locale.localeLanguage} isRequire isSearchable {...{ control, label: "language", name: "localeLanguage", options: [] }} />
                                </Col> */}

																<FieldText
																	require
																	{...{
																		name: fieldNames.translate_name,
																		placeholder: "name",
																		label: "name ",
																		errors: errors.translations?.[index],
																		value: value?.[index]?.[fieldNames.translate_name],
																		onChange: handleChangeInput,
																	}}
																/>
																<FieldText
																	require
																	{...{
																		name: fieldNames.translate_shortDesc,
																		placeholder: "shortDescription",
																		label: "shortDescription ",
																		value: value?.[index]?.[fieldNames.translate_shortDesc],
																		errors: errors.translations?.[index],
																		onChange: handleChangeInput,
																	}}
																/>
																{/* <FieldText
                                  require
                                  {...{
                                    name: fieldNames.translate_desc,
                                    placeholder: "desc",
                                    label: "desc ",
                                    value: value?.[index]?.[fieldNames.translate_desc],
                                    errors: errors.translations?.[index],
                                    onChange: handleChangeInput,
                                  }}
                                /> */}
																<LanguageSelector
																	{...{
																		name: fieldNames.translate_language,
																		label: "language",
																		value: value?.[index]?.[fieldNames.translate_language],
																		errors: errors.translations?.[index],
																		control,
																		onChange: (e) => handleChangeSelect({ ...e, name: fieldNames.translate_language }),
																	}}
																/>
															</Row>
														);
													})
												);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} disabled={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>

						<div className="row">
							<Col md="12 border p-4">
								<Controller
									name={fieldNames.allergies}
									control={control}
									render={({ field: { onChange, value } }) => {
										console.log("allergies", { value });
										return (
											<div className="card">
												<div className="">
													<h4 className="card-title">
														{"allergies"}({value?.length || 0})
													</h4>
												</div>

												<div className="">
													<div className="basic-form">
														<div className={styles.box}>
															{ALLERGENS_ARRAY?.map((x) => {
																const active = value?.find((y) => y.value == x.value);

																const handleSelect = () => {
																	if (active) onChange(value.filter((y) => y.value != x.value));
																	else {
																		let newValue = value || [];
																		newValue.push(x);
																		onChange(newValue);
																	}
																};

																return (
																	<div className={[styles.item, active && styles.active].join(" ")} onClick={handleSelect}>
																		<img className={styles.icon} src={x.icon} />
																		<span className={styles.label}> {x.label}</span>
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											</div>
										);
									}}
								/>
							</Col>
						</div>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
