import TableAction from "components/TableAction";

import isoToDate from "utils/isoToDate";
import { Button } from "react-bootstrap";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

export const tableData = ({ onClick, newRoute, isAdmin }) => {
	return [
		{
			header: "Invoice",
			Component: ({ param }) => {
				return (
					<>
						<span className="d-block mb-1">
							<a href={param?.invoice} download>
								<Button size="sm">{"Download"}</Button>
							</a>
						</span>
					</>
				);
			},
		},
		{
			header: "Amount",
			Component: ({ param }) => param.amount,
		},
		...(isAdmin
			? [
					{
						header: "branch",
						Component: ({ param }) => {
							const { base, edit } = routes.branch;
							return param?.branch ? (
								<Link to={`${base + edit}/${param.branch?.id}`} target="_blank">
									{param.branch?.name}{" "}
								</Link>
							) : (
								"-"
							);
						},
					},
			  ]
			: []),
		{
			header: "createdAt",
			Component: ({ param }) => isoToDate(param.createdAt),
		},
		// {
		//   header: "Status",
		//   Component: ({ param }) => <TableStatus {...{ param, onClick }} />,
		// },
		// {
		// 	header: "actions",
		// 	Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
		// },
	];
};
