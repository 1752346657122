const baseStrings = {
	translation: {
		name: "Name",
		dashboard: "Dashboard",
		order: "Order",
		product: "Product",
		category: "Category",
		user: "User",
		branch: "Branch",
		hotel: "Hotel",
		gallery: "Gallery",
		language: "Language",
		setting: "Setting",
		allStatus: "All Status",
		delete: "Delete",
		add: "Add",
		edit: "Edit",
		from: "From",
		to: "To",
		submit: "Submit",
		orderLists: "Order Lists",
		paymentType: "Payment Type",
		roomNumber: "Room Number",
		products: "Products",
		totalPrice: "Total Amount (Service Charge Included)",
		status: "Status",
		note: "Note",
		createdAt: "Created At",
		actions: "Action",
		search: "Search",
		newest: "Newest",
		image: "Image",
		price: "Price",
		translations: "Translations",
		stock: "Stock",
		integrationCode: "Integration Code",
		isFastDelivery: "Fast Delivery",
		isNewProduct: "New Product",
		listOrderNumber: "List Order",
		addProduct: "Add Product",
		active: "Active",
		parent: "Parent",
		categoryType: "Category Type",
		isNewCategory: "New Category",
		email: "Email",
		emailNotification: "Email Notification",
		phoneNotification: "Phone Notification",
		role: "Role",
		commercialTitle: "Commercial Title",
		currentNotWorkingReason: "Not Working Reason",
		startDate: "Start Date",
		endDate: "End Date",
		address: "Address",
		panelName: "Panel Name",
		taxAdministration: "Tax Administration",
		taxNo: "Tax No",
		code: "Code",
		isSystemWorking: "System Working",
		systemStartTime: "System Start Time",
		systemEndTime: "System End Time",
		qrCode: "QR Code",
		users: "Users",
		menus: "Menus",
		menu: "Menu",
		disable: "Disable",
		commission: "Commission",
		defaultLanguage: "Default Language",
		drink: "Drink",
		drinkInvoice: "Drink Invoice",
		foodInvoice: "Food Invoice",
		formal: "Formal",
		orderLimit: "Order Limit",
		phoneNumber: "Phone Number",
		roomCount: "Room Count",
		serviceCharge: "Service Charge",
		allOrders: "All Orders",
		overallSalesAchievements: "Overall Sales Achievements",

		dailyOrders: "Daily Orders",
		dailySalesAchievements: "Daily Sales Achievements",
		monthlyOrders: "Monthly Orders",
		monthlySalesAchievements: "Monthly Sales Achievements",
		total: "Total",
		finance: "Finance",
		totalAmount: "Total Amount",
		commission: "Commission",
		exportExcel: "Download As Excel",
		all: "All",
		multiplePrice: "Multiple Price",
		updateAuthor: "Update {{author}}",
		addAuthor: "Add {{author}}",
		editAuthor: "Edit {{author}}",
		listAuthor: "{{author}} list",
		authorLists: "{{author}} lists",
		contractAuthorLists: "Contract {{author}} lists",
		selectSearch: "you can search your item",
		totalCommission: "Total Commission",
		actions: "Actions",
		startTime: "Start Time",
		endTime: "End Time",
		isOrderLimit: "Order Limit",
		menus: "Menus",
		delivery: "Delivery",
		deliveries: "Deliveries",
		places: "Places",
		orderNumber: "Order Number",
		operation: "Operation",
		operationSide: "Operation Side",
		operationUnit: "Operation Unit",
		oldest: "Oldest",
		selectDate: "Select Date",
		refund: "Refund",
		canceled: "Canceled",
		paymentSuccess: "Payment Success",
		paymentFailed: "Payment Failed",
		waiting: "Waiting",
		sended: "Sended",
		preparing: "Preparing",
		delivered: "Delivered",
		onWay: "On Way",
		totalOrders: "Total Orders",
		card: "Card",
		online: "Online",
		cash: "Cash",
		payment: "Payment",
		isCardActive: "Is Card Payment Active",
		isCashActive: "Is Cash Payment Active",
		isOnlineActive: "Is Online Payment Active",
		exceptCategories: "Except Categories",
		statics: "Statics",
		orders: "Orders",
		detailedOrders: "Detailed Orders",
		priceWithoutKdv: "Price Without KDV",
		alcohol: "Alcohol",
		coldDrink: "Cold Drink",
		ibanName: "Iban Name",
		nameSurname: "Guest Name",
		buyPrice: "Buy Price",
		productHour: "Product Hour",
		hour: "Hour",
		updatedAt: "Updated At",
		firstName: "First Name",
		lastName: "Last Name",
		activities: "Activities",
		euro: "Euro",
		dollar: "Dollar",
		errors: {
			"is-require": "{{author}} is required",
			"is-not-valid": "{{author}} is not valid",
			"please-enter-your": "please enter {{author}}",
			"please-enter": "please enter {{author}}",
			"please-select-your": "please enter {{author}}",
			"must-be": "{{author}} {{kind }} should be",
			min: "The lowest character is {{author}}",
			max: "The maximum number of characters is {{author}}",
		},
		totalPriceTry: "Total Price Try",
		totalPriceEuro: "Total Price Euro",
		paymentCurrency: "Payment Currency",
		isRecommended: "Is Recommended",
		paymentPeriod: "Payment Period",
		limitAuthor: "Limit",
		"back-to-menu": "Back to menu",
		hotelName: "Hotel Names",
		orderCount: "Number of Orders",
		scanCount: "Number of Scans",
		ideaBox: "idea box",
		staticsDetailed: "Detailed Hotels Orders & Scans Report",
		"print-hotel-room-numbers": "Print hotel room numbers",
		served: "served",
		duplicated: "duplicated",
		"static-hotels": "Hotels Orders & Scans Report",
		"static-orders": "Order Guest Report",
		"order-product-report": "Order Product Report",
		"delivery-report": "Order Delivery Report",
		hotelName: "Hotel name",
		productName: "Product name",
		"except-categories": "Except categories",
		"except-products": "Except products",
		"cancel-note": "Cancel note",
		scannedRoom: "Scanned Room",
		branchProductName: "Branch product name",
		isMpuDisabled: "Mpu Disable",
		listOrderNumber: "List Order Number",
		"static-rooms": "static rooms",
		"rooms-scans-orders": "Rooms scans orders",
		"scan-count": "Scan Count",
		"download-all-room-numbers": "Download all room numbers",
		orderBranch: "Invoice Branch",
		invoiceBranch: "Invoice Branch",
		invoiceBranches: "Invoice Branches",
		invoiceHotel: "Invoice hotel",
		invoiceHotels: "Invoice hotels",
		hotelNames: "Hotel Names",
		commissionRate: "Commission rate",
		createdAt: "Creation Date & Time",
		hotelId: "Hotel Id",
		productId: "Product Id",
		suppliers: "Suppliers",
		kdv: "VAT",
		deliveryName: "Couriers Name",
		guestName: "Guest Name",
		onWayAt: "On Way Time Date & Time",
		assignedAt: "Creation Date & Time",
		assignmentDuration: "Assignment Duration",
		rideDuration: "Ride Duration",
		deliveryDuration: "Delivery Duration",
		preparingDuration: "Preparing Duration",
		deliveredAt: "Delivery Date & Time",
		productsBuyed: "Order Details",
		invoice: "Invoice",
		branches: "Suppliers",
		productNameTR: "Product Name TR",
		productNameEN: "Product Name EN",
		productNameAR: "Product Name AR",
		productNameFR: "Product Name FR",
		editDate: "Issue Date",
		orderCount: "Number of Orders",
		orderDetails: "Order Details",
		invoiceDetails: "Invoice Details",
		financeDetails: "Finance Details",
		contractDetails: "Contract Details",
		contract: "Contract",
		authorDetails: "{{author}} Details",
		downloadAsExcel: "Download as Excel",
		financeHotel: "Finance Hotel",
		financeBranch: "Finance Branch",
		financial: "Financial",
		sortBy: "Sort By",
		filterByStatus: "Filter By Status",
		totalOrderCount: "Total Order Count",
		totalRevenue: "Total Revenue",
		description: "Description",
		productPriceUpdateList: "Product Price Update List",
		productPriceUpdateLists: "Product Price Update Lists",
		productPriceUpdate: "Product Price Update",
		productPriceUpdates: "Product Price Updates",
		beforeBuyPrice: "Before Buy Price",
		backAuthor: "Back to {{author}}",
		editAuthor: "Edit {{author}}",
		publish: "Publish",
	},
};

export const en = baseStrings;
