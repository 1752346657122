import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { tableData } from "./tableInfo";
import { useTranslation } from "react-i18next";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CategorySelector, { convertCategoryData } from "containers/Category/CategorySelector";
import TableFiltersSelector from "components/TableFiltersSelector";
import MenuSelector, { convertMenuData } from "containers/Menus/MenuSelector";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";
import Branch from "containers/Branch";
import { Button } from "react-bootstrap";
import Loading from "components/Loading";
const List = () => {
	const { t } = useTranslation();
	const [checkList, setCheckList] = useState();
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance
	const button = {
		title: t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) }),
		link: newRoute.base + newRoute.add,
	};
	const { catName, catImage, menuName, ...QueryString } = useQueryString({
		sort: "desc",
	});
	const { page, limit } = QueryString;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const adminState = useSelector((state) => state.admin);
	const isAdmin = adminState.data.userType?.includes("admin");
	const isBranch = adminState.data.userType === "branch";

	// ---------------------------------------------- fetching functions
	const getting = async () => await axiosClient().get(api[API_NAME].contract);

	// ------------------------------------------------------- query Get
	const onSuccessViewContractMutation = (data) => {
		console.log("* * * onSuccessViewContractMutation :", { data });
		const url = data?.data?.contract;
		if (!url) return toastify.error({ title: "Data not found!" });
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_contract_get`,
		url: getting,
		params: { ...QueryString },
		enabled: false,
		onSuccess: onSuccessViewContractMutation,
	});
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: t(COMPONENT_NAMES[0]), link: newRoute.base },
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	return (
		<div className="flex align-items-center justify-content-center">
			<div className="p-5 border rounded">
				<p className="mb-0"> You can download contract from here.</p>
				<div className="d-flex align-items-center gap-2">
					<i className="mdi mdi-file-check display-1" />
					<Button disabled={isLoading} className="rounded d-flex align-items-center gap-2" variant="outline-primary" size="sm" onClick={refetch}>
						{isLoading ? <Loading size={"sm"} /> : ""} {"Download"}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default List;
