import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION, SUPPORT_PERMISSION, FINANCE_DATE } from "../enum";
import { tableData } from "./tableInfo";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import BoxWithLabelValue from "components/BoxWithLabelValue";
import { Col, Row } from "react-bootstrap";
import GalleryModal from "components/gallery/GalleryModal";
import FinanceModal from "../component/GalleryModal";
import moment from "moment";
import FinanceDatingComponent from "../FinanceDatingComponent";
import { useParams } from "react-router-dom";

const List = () => {
	const [checkList, setCheckList] = useState();
	const { t } = useTranslation();
	const adminState = useSelector((state) => state.admin);
	const validPermission = adminState?.data?.permissions === true;
	let permissions = validPermission || adminState?.data?.permissions?.[PERMISSION];
	if (!permissions) permissions = adminState?.data?.permissions?.[SUPPORT_PERMISSION?.find((x) => adminState?.data?.permissions?.[x])];

	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance
	let { years } = useParams();

	let defaultYears = FINANCE_DATE[1];

	years = years || defaultYears;

	let d = moment();
	if (years) d.year(years);
	const minMonthDate = new Date(d.startOf("month"));
	const maxMonthDate = new Date(d.endOf("month"));
	const QueryString = useQueryString({ sort: "desc", from: minMonthDate, to: maxMonthDate });

	// let d = new Date();
	// let from = new Date(d.setHours(0, 0, 0, 0));
	// let to = new Date(d.setHours(23, 59, 59, 999));
	// const QueryString = useQueryString({ sort: "desc", from, to });
	const { page } = QueryString;
	const dispatch = useDispatch();

	const isBranch = adminState.data.userType === "branch";
	const isHotel = adminState.data.userType === "hotel";
	const isAdmin = adminState.data.userType?.includes("admin");
	// ---------------------------------------------- fetching functions
	const gettingNavData = async () =>
		await axiosClient().get(api[API_NAME].invoice.base, {
			// params: { ...QueryString },
		});

	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	// ------------------------------------------------------- query Get
	// const invoiceData = useQueryCustom({
	//   name: `${api[API_NAME].invoice.base}_get`,
	//   url: gettingNavData,
	//   // params: { ...QueryString },
	// });
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
		enabled: !!(validPermission || permissions?.list),
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: t(COMPONENT_NAMES[0]), link: newRoute.base },
		{ title: "Details" },
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		pathId: "hotel.id",
		active: { show: (permissions?.list || validPermission) && true },
		disable: { edit: true, delete: true },
		isBranch,
		isHotel,
		isAdmin,
	});

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, "excel_export.xlsx");

		// const ws = XLSX.utils.json_to_sheet(apiData);
		// /* custom headers */
		// XLSX.utils.sheet_add_aoa(ws, [head], { origin: "A1", });
		// const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		// const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		// const data = new Blob([excelBuffer], { type: fileType });
		// FileSaver.saveAs(data, fileName + fileExtension);
	};

	const handleExcel = () => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
		];

		const head = [
			t("name"),
			t("commercialTitle"),
			t("invoice"),
			t("commission"),
			t("orderCount"),
			t("totalAmount"),
			t("totalCommission"),
			t("date"),
			t("clock"),
			t("iban"),
			t("ibanName"),
		];
		const apiData = data?.result?.map((y) => {
			const date = moment(y?.createdAt);
			return {
				[t("name")]: y?.hotel.name,
				[t("commercialTitle")]: y?.hotel.commercialTitle,
				[t("invoice")]: y?.hotel.invoice,
				[t("commission")]: y?.hotel.commission,
				[t("orderCount")]: y?.orderCount,
				[t("totalAmount")]: y?.totalAmount,
				[t("totalCommission")]: y?.totalCommission,
				[t("date")]: date.format("DD-MM-YYYY"),
				[t("clock")]: date.format("HH:mm:ss"),
				[t("iban")]: y?.hotel.iban,
				[t("ibanName")]: y?.hotel.ibanName,
			};
		});
		exportToCSV({ apiData, fileName: "exc", head, cols });
	};
	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");

	const headButtonData = [
		{
			label: "Total Progress Payment",
			value: "0  £",
		},
		{
			label: "Total Paid",
			value: "0  £",
		},
		{
			label: "Total Remaining",
			value: "0  £",
		},
		{
			label: "Most Recent Request Date",
			value: "2023.12.05",
		},
	];

	const [showModal, setShowModal] = useState(false);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};

	const button = { title: "Request Payment", onClick: handleGalleryModal };

	return (
		<div>
			<FinanceModal {...{ showModal, handleModal: handleGalleryModal, isGalleryDisabled: true }} />
			<PageTitle title={t("authorDetails", { author: t(COMPONENT_NAMES[0]) })} breadcrumbs={breadcrumbs} button={!isAdmin && button} />

			{!isAdmin ? (
				<Row className="my-5">
					{headButtonData?.map((x) => {
						return (
							<Col xs={3}>
								{" "}
								<BoxWithLabelValue {...x} />
							</Col>
						);
					})}
				</Row>
			) : (
				""
			)}

			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),
						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data.total,
					pagination: {
						activePage: page,
						// pages: Math.ceil(data.total / limit),
						// limited: 2,
					},
					dating: {
						component: <FinanceDatingComponent {...{ QueryString, baseUrl: routes.finance.base + routes.finance.list }} />,

						// props: {
						// 	showMonthYearPicker: true,
						// 	dateFormat: "MMMM d, yyyy",
						// },
					},
					excelButton: {
						show: permissions?.allOrders || validPermission,
						disabled: !data?.result,
						onClick: handleExcel,
					},
				}}
			/>
		</div>
	);
};

export default List;
