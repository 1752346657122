import * as QRCode from "easyqrcodejs";

const generateQrCode = (text, qrCode, roomNumber, options) => {
	options = options || {};
	options.width = options?.width || 195;
	options.height = options?.height || 195;
	options.logo = options.logo || {};
	options.logo.width = options?.logo?.width || 60;
	options.logo.height = options?.logo?.height || 60;

	const width = options?.width * 10;
	const height = options?.height * 10;
	const widthLogo = options?.logo?.width * 10;
	const heightLogo = options?.logo?.height * 10;

	const setting = {
		// ====== Basic
		text: text,
		width: width,
		height: height,
		correctLevel: QRCode.CorrectLevel.H,
		colorDark: options?.background || "#000",
		colorLight: options?.color || "#fff",
		// ====== Quiet Zone
		quietZone: 1,
		quietZoneColor: "#fff",
		// ==== Images format
		format: "SVG", // 'PNG', 'JPG'
		compressionLevel: 9, // ZLIB compression level (0-9). default is 6
		quality: 1, // An object specifying the quality (0 to 1). default is 0.75. (JPGs only)
		// ====== Logo
		...(roomNumber && {
			logo: generateImageWithNumber(roomNumber, options),
			logoWidth: options?.logo?.width,
			logoHeight: options?.logo?.height,
			logoBackgroundColor: "#fff",
			logoBackgroundTransparent: false, // Whether use transparent image, default is false
		}),
	};

	// new QRCode(qrCode, setting);

	const qrCodes = new QRCode(qrCode, setting);
	console.log({ qrCodes });
	qrCodes.resize(options?.width, options?.height);
};

function generateImageWithNumber(roomNumber, options) {
	roomNumber = String(roomNumber);
	const baseSize = options?.logo?.bgWhite ? 50 : 35;
	const width = baseSize;
	const height = baseSize;
	const lineHeight = 1.2; // Adjust this value to control the line height

	// Create SVG element
	const svgNS = "http://www.w3.org/2000/svg";
	const svg = document.createElementNS(svgNS, "svg");
	svg.setAttribute("width", width);
	svg.setAttribute("height", height);
	svg.setAttribute("viewBox", `0 0 ${width} ${height}`);

	if (!options?.logo?.bgWhite) {
		const rect = document.createElementNS(svgNS, "rect");
		rect.setAttribute("width", "100%");
		rect.setAttribute("height", "100%");
		rect.setAttribute("fill", "#FFF");
		svg.appendChild(rect);

		const parts = roomNumber?.length > 8 ? roomNumber.split(" ") : [roomNumber];
		let fontSize;
		if (parts?.length === 1) fontSize = width / (roomNumber.length / 1.5);
		else if (parts?.length === 2) fontSize = width / (roomNumber.length / 2.9);
		else fontSize = width / (roomNumber.length / 3.5);
		if (fontSize > width) fontSize = width;
		console.log({ fontSize });
		const text = document.createElementNS(svgNS, "text");
		text.setAttribute("x", "50%");
		text.setAttribute("y", "50%");
		text.setAttribute("dominant-baseline", "middle");
		text.setAttribute("text-anchor", "middle");
		text.setAttribute("font-family", "Arial");
		text.setAttribute("font-weight", "700");
		text.setAttribute("fill", options?.textColor || "#000");
		text.setAttribute("font-size", fontSize);
		parts.forEach((part, index) => {
			const tspan = document.createElementNS(svgNS, "tspan");
			tspan.setAttribute("x", "50%");
			if (parts?.length == 2) tspan.setAttribute("dy", index === 0 ? "-5px" : `${lineHeight}em`);
			else if (parts?.length > 2) tspan.setAttribute("dy", index === 0 ? "-7px" : `${lineHeight}em`);
			else tspan.setAttribute("dy", index === 0 ? "2px" : `${lineHeight}em`);
			tspan.textContent = part;
			text.appendChild(tspan);
		});

		svg.appendChild(text);
	}

	const svgData = new XMLSerializer().serializeToString(svg);
	const svgBase64 = `data:image/svg+xml;base64,${btoa(svgData)}`;

	return svgBase64;
}

// function generateImageWithNumber(roomNumber, options) {
// 	roomNumber = String(roomNumber);
// 	const baseSize = options?.logo?.bgWhite ? 50 : 100;
// 	// const scale = 4; // Scale factor for higher resolution
// 	const width = baseSize;
// 	const height = baseSize;

// 	// Create SVG element
// 	const svgNS = "http://www.w3.org/2000/svg";
// 	const svg = document.createElementNS(svgNS, "svg");
// 	svg.setAttribute("width", width);
// 	svg.setAttribute("height", height);
// 	svg.setAttribute("viewBox", `0 0 ${width} ${height}`);

// 	if (!options?.logo?.bgWhite) {
// 		const rect = document.createElementNS(svgNS, "rect");
// 		rect.setAttribute("width", "100%");
// 		rect.setAttribute("height", "100%");
// 		rect.setAttribute("fill", "#FFF");
// 		svg.appendChild(rect);

// 		let fontSize = width / (roomNumber.length / 2);
// 		if (fontSize > width) fontSize = width;

// 		const text = document.createElementNS(svgNS, "text");
// 		text.setAttribute("x", "50%");
// 		text.setAttribute("y", "50%");
// 		text.setAttribute("dominant-baseline", "middle");
// 		text.setAttribute("text-anchor", "middle");
// 		text.setAttribute("font-size", fontSize);
// 		text.setAttribute("font-family", "Arial");
// 		text.setAttribute("font-weight", "700");
// 		text.setAttribute("fill", options?.textColor || "#000");
// 		text.textContent = roomNumber;

// 		svg.appendChild(text);
// 	}

// 	const svgData = new XMLSerializer().serializeToString(svg);
// 	const svgBase64 = `data:image/svg+xml;base64,${btoa(svgData)}`;

// 	return svgBase64;
// }

// function generateImageWithNumber(roomNumber, options) {
// 	roomNumber = String(roomNumber);
// 	const baseSize = options?.logo?.bgWhite ? 50 : 1000;
// 	const scale = 4; // Scale factor for higher resolution
// 	const width = baseSize * scale;
// 	const height = baseSize * scale;

// 	// Create SVG element
// 	const svgNS = "http://www.w3.org/2000/svg";
// 	const svg = document.createElementNS(svgNS, "svg");
// 	svg.setAttribute("width", width);
// 	svg.setAttribute("height", height);
// 	svg.setAttribute("viewBox", `0 0 ${width} ${height}`);

// 	if (!options?.logo?.bgWhite) {
// 		const rect = document.createElementNS(svgNS, "rect");
// 		rect.setAttribute("width", "100%");
// 		rect.setAttribute("height", "100%");
// 		rect.setAttribute("fill", "#FFF");
// 		svg.appendChild(rect);

// 		const text = document.createElementNS(svgNS, "text");
// 		text.setAttribute("x", "50%");
// 		text.setAttribute("y", "50%");
// 		text.setAttribute("dominant-baseline", "middle");
// 		text.setAttribute("text-anchor", "middle");
// 		text.setAttribute("font-family", "Arial");
// 		text.setAttribute("font-weight", "700");
// 		text.setAttribute("fill", options?.textColor || "#000");

// 		// Calculate and set font size dynamically based on text length
// 		let fontSize = width; /// (roomNumber.length / 2); // Adjust factor as needed
// 		if (fontSize > width / 2) fontSize = width / 2;
// 		text.setAttribute("font-size", fontSize);

// 		// Split text into multiple lines if too long
// 		if (roomNumber.length > 10) {
// 			// Adjust length threshold as needed
// 			const lines = splitText(roomNumber, 10); // Split text every 10 characters
// 			lines.forEach((line, index) => {
// 				const tspan = document.createElementNS(svgNS, "tspan");
// 				tspan.setAttribute("x", "50%");
// 				tspan.setAttribute("dy", index === 0 ? "0" : fontSize * 1.2); // Adjust line spacing
// 				tspan.textContent = line;
// 				text.appendChild(tspan);
// 			});
// 		} else {
// 			text.textContent = roomNumber;
// 		}

// 		svg.appendChild(text);
// 	}

// 	const svgData = new XMLSerializer().serializeToString(svg);
// 	const svgBase64 = `data:image/svg+xml;base64,${btoa(svgData)}`;

// 	return svgBase64;
// }

// function splitText(text, chunkSize) {
// 	const chunks = [];
// 	for (let i = 0; i < text.length; i += chunkSize) {
// 		chunks.push(text.substr(i, chunkSize));
// 	}
// 	return chunks;
// }

function convertImageToSVG(imageDataURL) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = imageDataURL;
		img.onload = function () {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0);

			const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
			let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${canvas.width}" height="${canvas.height}">`;

			for (let i = 0; i < data.length; i += 4) {
				const r = data[i];
				const g = data[i + 1];
				const b = data[i + 2];
				const a = data[i + 3] / 255;

				svg += `<rect x="${(i / 4) % canvas.width}" y="${Math.floor(i / 4 / canvas.width)}" width="1" height="1" fill="rgba(${r},${g},${b},${a})"/>`;
			}

			svg += `</svg>`;
			resolve(svg);
		};

		img.onerror = function (error) {
			reject(error);
		};
	});
}

// function generateImageWithNumber(roomNumber, options) {
// 	roomNumber = String(roomNumber);
// 	// const canvas = canvasRef.current;
// 	const scale = 10;
// 	const baseSize = options?.logo?.bgWhite ? 50 : 1000;
// 	var canvas = document.createElement("canvas");
// 	canvas.className = "hiddenCanvas";
// 	canvas.width = baseSize * scale;
// 	canvas.height = baseSize * scale;
// 	console.log({ options });
// 	var body = document.getElementsByTagName("body")[0];
// 	body.appendChild(canvas);

// 	const ctx = canvas.getContext("2d");
// 	// ctx.scale(scale, scale);

// 	if (!options?.logo?.bgWhite) {
// 		ctx.fillStyle = "#FFF";
// 		ctx.fillRect(0, 0, canvas.width, canvas.height);
// 		let fontSize = canvas.width / (roomNumber.length / 1.5);
// 		// console.log({ width: canvas.width, roomNumber: roomNumber?.trim(" "), fontSize }, roomNumber.length, canvas.width / roomNumber?.trim(" ").length);
// 		if (fontSize > canvas.width) fontSize = canvas.width;
// 		ctx.fillStyle = options?.textColor || "#000";
// 		ctx.font = `700 ${fontSize}px Arial`;
// 		ctx.textAlign = "center";
// 		ctx.textBaseline = "middle";
// 		ctx.fillText(roomNumber, canvas.width / 2, canvas.height / 2);
// 	}
// 	// setTimeout(function () {
// 	body.removeChild(canvas);
// 	// }, 5000);

// 	return canvas.toDataURL("image/png", 1.0);
// }

export default generateQrCode;

// import { createCanvas } from 'canvas';
// import { Injectable } from '@nestjs/common';
// import { IQrCodeService } from './qr-code.interface';
// import { config } from 'src/common/config';
// import EasyQrCode = require('easyqrcodejs-nodejs');
// import { UploadDto } from 'src/aws/dto/aws.dto';
// @Injectable()
// export class QrCodeService implements IQrCodeService {
//     async createQrCodeWithNumber(
//         qrCodeId: string,
//         roomNumber: string,
//     ): Promise<string> {
//         //* url will come from backend and frontend will add the room number to this url
//         const qrCodeUrl = https://room-services.net/sayfa/urunler?ouid=019B07E0-5292-48D5-BA59-3F6176650829?roomNumber=${roomNumber};

//         const options = {
//             // ====== Basic
//             text: qrCodeUrl,
//             width: 1080,
//             height: 1080,
//             correctLevel: EasyQrCode.CorrectLevel.H,
//             // ====== Quiet Zone
//             quietZone: 1,
//             quietZoneColor: '#fff',
//             // ==== Images format
//             format: 'PNG', // 'PNG', 'JPG'
//             compressionLevel: 9, // ZLIB compression level (0-9). default is 6
//             quality: 1, // An object specifying the quality (0 to 1). default is 0.75. (JPGs only)
//             // ====== Logo
//             logo: this.generateImageWithNumber(roomNumber),
//             logoWidth: 320,
//             logoHeight: 320,
//             logoBackgroundColor: '#fff',
//             logoBackgroundTransparent: false, // Whether use transparent image, default is false
//         };
//         const qrCode = await new EasyQrCode(options).toDataURL('image/png');

//         // Extract the base64-encoded data
//         const [, encodedData] = qrCode.split(',');

//         // Decode the base64 data
//         const decodedData = Buffer.from(encodedData, 'base64');
//         const filename = ${qrCodeId}-${roomNumber}.png;
//         const { path } = await this.awsService.upload(
//             new UploadDto(decodedData, filename),
//         );
//         return path;
//     }
//     generateImageWithNumber(roomNumber: string): string {
//         // Create a canvas
//         const canvas = createCanvas(1080, 1080);
//         const ctx = canvas.getContext('2d');

//         // Draw something (transparent background)
//         ctx.clearRect(0, 0, 1080, 1080);

//         // Draw the number in the middle
//         ctx.fillStyle = '#000';
//         ctx.font = ${1000 / roomNumber.length}px Arial;
//         ctx.textAlign = 'center';
//         ctx.textBaseline = 'middle';
//         ctx.fillText(roomNumber, canvas.width / 2, canvas.height / 2);

//         // return the image
//         return canvas.toDataURL('image/png');
//     }
// }
// export const QR_CODE_SERVICE_TOKEN = Symbol('QR_CODE_SERVICE_TOKEN');
