import { ERRORS } from "enumerations";
import * as yup from "yup";
export const fieldNames = {
	oldProduct: "oldProduct",
	newProduct: "newProduct",
	notify: "notify",
	canceled: "canceled",
	products: "products",
};

const products = yup
	.object({
		// [fieldNames.oldProduct]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.oldProduct)).label(fieldNames.oldProduct),
		[fieldNames.canceled]: yup.boolean().label(fieldNames.canceled),
		[fieldNames.newProduct]: yup.object().nullable(),
		// .when(fieldNames.canceled, {
		// 	is: false,
		// 	then: yup
		// 		.object()
		// 		.typeError(ERRORS.pleaseEnterYour(fieldNames.newProduct))
		// 		.required(ERRORS.pleaseEnterYour(fieldNames.newProduct))
		// 		.label(fieldNames.newProduct),
		// 	otherwise: yup.object().nullable(),
		// })
		// [fieldNames.newProduct]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.newProduct)).label(fieldNames.newProduct),
		[fieldNames.notify]: yup.boolean().label(fieldNames.notify),
	})
	.defined();

export const validation = yup.object().shape({
	// [fieldNames.newProduct]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.newProduct)).label(fieldNames.newProduct),
	[fieldNames.products]: yup.array().of(products).required(ERRORS.pleaseEnterYour(fieldNames.products)),
});

// import { ERRORS } from "enumerations";
// import * as yup from "yup";
// export const fieldNames = {
//   oldProduct: "oldProduct",
//   newProduct: "newProduct",
//   notify: "notify",
//   canceled: "canceled",
//   products: "products",
// };

// const products = yup
//   .object({
//     // [fieldNames.oldProduct]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.oldProduct)).label(fieldNames.oldProduct),
//     [fieldNames.canceled]: yup.boolean().default(false).label(fieldNames.canceled),
//     [fieldNames.newProduct]: yup
//       .object()
//       .when(fieldNames.canceled, {
//         is: false,
//         then: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.newProduct)).label(fieldNames.newProduct),
//         otherwise: yup.object(),
//       }),
//     // [fieldNames.newProduct]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.newProduct)).label(fieldNames.newProduct),
//     [fieldNames.notify]: yup.boolean().default(false).label(fieldNames.notify),
//   })
//   .defined();

// export const validation = yup.object().shape({
//   // [fieldNames.newProduct]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.newProduct)).label(fieldNames.newProduct),
//   [fieldNames.products]: yup
//     .array()
//     .of(products)
//     .required(ERRORS.pleaseEnterYour(fieldNames.products)),
// });
